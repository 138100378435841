import { useState } from "react";
import { axios } from "../../../../lib/axios";

export default function GlobalPayoutFeesViewModel() {
    const [success, setSuccess] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState("");
    const [globalPayoutFeesData, setGlobalPayoutFeesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    const headers = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function fetchGlobalPayoutFeeList(searchString, page) {
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/admin/payout_fees/?page=${page}&search=${searchString}`);
            setGlobalPayoutFeesData(response);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setMessageDisplay("Failed to fetch global payout fee list");
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function addGlobalPayoutFee(params) {
        setMessageDisplay("");
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/payout_fees/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to create global payout fee");
            }
            
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function editGlobalPayoutFee(params, id) {
        setMessageDisplay("");
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/payout_fees/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update global payout fee");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function toggleGlobalPayoutFee(id) {
        setMessageDisplay("");
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/payout_fees/${id}/toggle/`, {}, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update global payout fee");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    return {
        fetchGlobalPayoutFeeList,
        addGlobalPayoutFee,
        editGlobalPayoutFee,
        toggleGlobalPayoutFee,
        globalPayoutFeesData,
        success,
        messageDisplay,
        isLoading,
    }
}