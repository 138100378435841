import { useState } from "react";
import { axios } from "../../../../lib/axios";

export default function MerchantPayoutFeesViewModel() {
    const [success, setSuccess] = useState(false);
    const [messageDisplay, setMessageDisplay] = useState("");
    const [merchantPayoutFeesData, setMerchantPayoutFeesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [shoulderEnabled, setShoulderEnabled] = useState(false);
    const [userFeesEnabled, setUserFeesEnabled] = useState(false);
    
    const headers = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }

    async function fetchMerchantPayoutFeeList(searchString, page, id) {
        setIsLoading(true);
        setIsLoading(true);
        try {
            const response = await axios.get(`/api/admin/user_payout_fees/${id}/?page=${page}&search=${searchString}`);
            setMerchantPayoutFeesData(response);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setMessageDisplay("Failed to fetch merchant payout fee list");
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function addMerchantPayoutFee(params, id) {
        setMessageDisplay("");
        setIsLoading(true);
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + `/api/admin/user_payout_fees/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to create merchant payout fee");
            }
            
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function editMerchantPayoutFee(params, id) {
        setMessageDisplay("");
        setIsLoading(true);
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/user_payout_fees/${id}/`, params, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update merchant payout fee");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function toggleMerchantPayoutFee(id) {
        setMessageDisplay("");
        setIsLoading(true);
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/user_payout_fees/${id}/toggle/`, {}, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update merchant payout fee");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function enablePayoutFee(id) {
        setMessageDisplay("");
        setIsLoading(true);
        try {
            const response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/user_payout_fees/settings/${id}/toggle/`, {
                "setting_to_update": "shoulder_enabled"
            }, headers);
            setSuccess(true);
            setMessageDisplay(response.message);
            setIsLoading(false);
        } catch (error) {
            if (error.response.data.message != "") {
                setMessageDisplay(error.response.data.message);
            } else {
                setMessageDisplay("Failed to update merchant payout fee");
            }
            setSuccess(false);
            setIsLoading(false);
        }
    }

    async function getUserPayoutFeesSettings(id) {
        setMessageDisplay("");
        setIsLoading(true);
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `/api/admin/user_payout_fees/settings/${id}/`);
            setShoulderEnabled(response.data.shoulder_enabled);
            setUserFeesEnabled(response.data.user_fees_enabled);
            setSuccess(true);
            setIsLoading(false);
        } catch (error) {
            setSuccess(false);
            setIsLoading(false);
        }
    }

    return {
        fetchMerchantPayoutFeeList,
        addMerchantPayoutFee,
        editMerchantPayoutFee,
        toggleMerchantPayoutFee,
        enablePayoutFee,
        getUserPayoutFeesSettings,
        merchantPayoutFeesData,
        success,
        messageDisplay,
        isLoading,
        setShoulderEnabled,
        shoulderEnabled,
        userFeesEnabled,
    }
}