import React from 'react'
import axios from 'axios'
import {message, Spin} from 'antd'
import ReviewCard from './review_form/review_card'
//business info
import ReviewBusinessInfo from './review_form/review_business_info'
import BusinessInfo from './application_form/business_info'
//contact info
import ReviewContactInfo from './review_form/review_contact_info'
import ContactInfo from './application_form/contact_info'
//business doc
import ReviewBusinessDocu from './review_form/reveiw_business_docu'
import BusinessDocu from './application_form/business_docu'
//signatroy
import SignatoryInfo from './application_form/signatory_info'
import ReviewSignatoryInfo from './review_form/review_signatory_info'
//moa and nda
import MoaNda from './application_form/moa_nda'
import ReviewMoaNda from './review_form/review_moa_nda'
//bank acct
import BankAccount from './application_form/bank_account'
import ReviewBankAccount from './review_form/review_bank_account'
import { history } from '../../../store/history'
import {isMobile} from 'react-device-detect'
import kybStyle from './kybStyle'
import { backToTop } from '../../constants/constants'

import ReviewAMLAssessment from '../../aml_assessment/review_aml_assessment'

const MainReview = props => {

    React.useEffect(() => {
        // fetchData()
    }, [])
    const kyb = kybStyle()
    const datas = props.datas
    const kybStep = localStorage.getItem("KYBStep")

    const [editStep1, setEditStep1] = React.useState(true)
    const [editStep2, setEditStep2] = React.useState(true)
    const [editStep3, setEditStep3] = React.useState(true)
    const [editStep4, setEditStep4] = React.useState(true)
    const [editStep5, setEditStep5] = React.useState(true)
    const [editStep6, setEditStep6] = React.useState(true)
    const [openModal, setOpenModal] = React.useState(false)
    const [editMode, setEditMode] = React.useState(false)
    const [showSpin, setShowSpin] = React.useState(false)
    const cardDivsRefs = props.cardDivsRefs;
    
    const [childData, setChildData] = React.useState(datas ? datas : [])
    const [fetchDone, setFetchDone] = React.useState(datas ? true : false)
    const [showAlert, setShowAlert] = React.useState(true)
    const [alertMessage, setAlertMessage] = React.useState("")
    const [step, setStep] = React.useState(0)
    const [remarks, setRemarks] = React.useState(datas ? datas.remarks : '')
    const [dateSubmitted, setDateSubmitted] = React.useState(datas ? datas.created_at : '')
    const [offSpin, setOffSpin] = React.useState(true)
    const [bt, setBT] = React.useState(0)
    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        }
    }      
    const isFHD = window.screen.width * window.devicePixelRatio >= 1920 && window.screen.height * window.devicePixelRatio >= 1080;
    const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
    const isMac = window.screen.width * window.devicePixelRatio === 1440 && window.screen.height * window.devicePixelRatio === 900;
    const isSmallReso = window.screen.width * window.devicePixelRatio === 1331 && window.screen.height * window.devicePixelRatio === 669;
    const isDell = window.screen.width * window.devicePixelRatio === 1360 && window.screen.height * window.devicePixelRatio === 765;
    const isIpadPro = window.screen.width * window.devicePixelRatio === 1024 && window.screen.height * window.devicePixelRatio === 1366;

    function editStep(step){
        backToTop()
        if(step === 1){
            setEditStep1(!editStep1)
            setEditMode(!editMode)
        }
        else if(step === 2){
            setEditStep2(!editStep2)
            setEditMode(!editMode)

        }
        else if(step === 3){
            setEditStep3(!editStep3)
            setEditMode(!editMode)

        }
        else if(step === 4){
            setEditStep4(!editStep4)
            setEditMode(!editMode)

        }
        else if(step === 5){
            setEditStep5(!editStep5)
            setEditMode(!editMode)

        }
        else if(step === 6){
            setEditStep6(!editStep6)
            setEditMode(!editMode)

        }
    }

    let alertDiv = <div hidden={showAlert} 
                        style={{
                            backgroundColor: 'rgba(226, 76, 76, 0.1)',
                            borderLeft: '4px solid #E24C4C',
                            width: '100%',
                            borderRadius: '4px',
                            marginBottom: '20px',
                            marginTop: '20px',
                            textAlign: 'left',
                            padding: '10px',
                        }}>
                        {alertMessage}
                    </div>

function submitApplication() {
    if(datas.business_type == 0) {
        if((!datas.dti_registration_attachment) ||
        (!datas.business_permit_attachment) ||
        (!datas.bir_2303_attachment)) {
            message.error("Please Update your business Documents")
        }

        else {
            setOpenModal(true)
        }
    }

    else if(datas.business_type == 1){
        if((!datas.sec_registration_attachment) ||
        (!datas.articles_of_incorporation_attachment) ||
        (!datas.secretary_certificate_attachment) ||
        (!datas.bir_2303_attachment) ||
        (!datas.business_permit_attachment) ||
        (!datas.bylaws_attachment)) {
            message.error("Please Update your business Documents")
        }

        else {
            setOpenModal(true)
        }
    }

    else if(datas.business_type == 2) {
        if((!datas.sec_registration_attachment) ||
        (!datas.articles_of_incorporation_attachment) ||
        (!datas.secretary_certificate_attachment) ||
        (!datas.bir_2303_attachment) ||
        (!datas.business_permit_attachment)) {
            message.error("Please Update your business Documents")
        }

        else {
            setOpenModal(true)
        }
    }
}

    async function submitStep(params, step){
        setOffSpin(false)

        try{
            let url = process.env.REACT_APP_API_URL + "/api/kyb/";

            
            if(step === 1 || step === 2 || step === 6){
                params.submission_id = datas.id
            }

            else if(step === 4){
                params.append("submission_id", datas.id)
            }

            if(datas.step > 1 && step === 1) {
                if(params.email != localStorage.getItem('spocEmail')) {
                    setOffSpin(true)
                    setShowAlert(false)
                    backToTop()
                    setAlertMessage("Email Address is not editable");
                    setTimeout(() => {
                        setShowAlert(true)
                    }, 5000)

                    return;

                }
            }

            let response = await axios.patch(url,params,yourConfig)
            //response

            if(response.data.status == "failed"){
                setOffSpin(true)
                setShowAlert(false)
                backToTop()
                setAlertMessage(response.data.message);
                setTimeout(() => {
                    setShowAlert(true)
                }, 5000)


            }

            else if(response.data.status == "success"){
                setOffSpin(true)
                backToTop()
                setChildData(response.data.data)
                setStep(response.data.data.step)
                setBT(response.data.data.business_type)
                if(step == 1){
                    if(localStorage.getItem('lastBusinessType') != response.data.data.business_type)
                    {
                        message.warning("Please update your business documents", 5)
                        setEditStep3(!editStep3)
                        setEditMode(!editMode)
                    }
                    setEditMode(!editMode)
                    setEditStep1(!editStep1)                
                }
                else if(step === 2){
                    setEditStep2(!editStep2)
                    setEditMode(!editMode)

                }
                else if(step === 3){
                    setEditStep3(!editStep3)
                    setEditMode(!editMode)
                }
                else if(step === 4){
                    setEditStep4(!editStep4)
                    setEditMode(!editMode)
                }
                else if(step === 5){
                    setEditStep5(!editStep5)
                    setEditMode(!editMode)
                }
                else if(step === 6){
                    setEditStep6(!editStep6)
                    setEditMode(!editMode)
                }
                else if(response.data.data.is_aml_required == true || response.data.data.step == 8 || response.data.data.step == 9){
                    window.location.reload()
                }
            }

            setOffSpin(true)

        }
        
        catch(error){
            console.log(error.message)
            setOffSpin(true)
        }
    }

    return(
        <div>
        
            <div hidden={kybStep >= 11 || !editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5 || !editStep6}>
                <ReviewCard segments={props.segments} datas={childData} submitStep={submitStep} remarks={remarks} submitted={dateSubmitted} openModal={openModal} setOpenModal={setOpenModal} submitApplication={submitApplication}/>
                
                {/* <div style={{marginBottom: 32}} hidden={kybStep >= 8}>
                    <div className={kyb.headerReview}>
                        Review Page
                    </div>
                    <div className={kyb.normalText}>
                        You may edit / update your application by clicking <b>EDIT</b> button.
                    </div>
                </div> */}
            </div>


            {
                editStep1 ? 
                <div hidden={!editStep2 || !editStep3 || !editStep4 || !editStep5 || !editStep6}>
                    <ReviewBusinessInfo segments={props.segments} datas={childData} openEdit={editStep} cardDivRef={cardDivsRefs.business_info_div_ref}/>
                </div>
                :  
                !editStep1 && fetchDone ?  
                <BusinessInfo segments={props.segments} openEdit={editStep} offSpin={offSpin} submitStep={submitStep} datas={childData} alertMessage={alertDiv}/>
                : ''

            }
            {
                editStep2 ? 
                <div hidden={!editStep1 || !editStep3 || !editStep4 || !editStep5 || !editStep6}>
                    <ReviewContactInfo segments={props.segments} datas={childData} openEdit={editStep} cardDivRef={cardDivsRefs.contact_info_div_ref}/>
                </div>
                :
                <ContactInfo segments={props.segments} openEdit={editStep} offSpin={offSpin} submitStep={submitStep} alertMessage={alertDiv}/>
            }
            {
                !editStep3 ?
                <BusinessDocu segments={props.segments} editMode={editMode} offSpin={offSpin} openEdit={editStep} submitStep={submitStep} businessType={bt} datas={childData} alertMessage={alertDiv}/>
                :
                <div hidden={!editStep1 || !editStep2 || !editStep4 || !editStep5 || !editStep6}>
                    <ReviewBusinessDocu segments={props.segments} openEdit={editStep} datas={childData} cardDivRef={cardDivsRefs.business_document_div_ref}/>
                </div>
            }
            {
                !editStep4 ?
                <SignatoryInfo segments={props.segments} datas={childData} editMode={editMode} offSpin={offSpin} openEdit={editStep} submitStep={submitStep} />
                :
                <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep5 || !editStep6}>
                    <ReviewSignatoryInfo segments={props.segments} datas={childData} openEdit={editStep}  cardDivRef={cardDivsRefs.signatory_info_div_ref}/>
                </div>

            }
            {
                !editStep5 ?
                <MoaNda segments={props.segments} editMode={editMode} openEdit={editStep} offSpin={offSpin} submitStep={submitStep} datas={childData}/>
                :
                <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep6}>
                    <ReviewMoaNda segments={props.segments} datas={childData} openEdit={editStep} cardDivRef={cardDivsRefs.moa_nda_div_ref}/>
                </div>
      
            }
            {
                !editStep6 ?
                <BankAccount segments={props.segments} openEdit={editStep} offSpin={offSpin} submitStep={submitStep} datas={childData.bank_account}/>
                :
                <div hidden={!editStep1 || !editStep2 || !editStep3 || !editStep4 || !editStep5}>
                    <ReviewBankAccount segments={props.segments} datas={childData} openEdit={editStep} cardDivRef={cardDivsRefs.bank_account_div_ref}/>
                </div>
      
            }
            {
                childData.is_aml_required && (
                    <div>
                        <ReviewAMLAssessment segments={props.segments} datas={childData} openEdit={editStep} cardDivRef={cardDivsRefs.aml_assessment_div_ref}/>
                    </div>
                )
            }

            {/* user cannot edit/resubmit application once rejected */}
            <div hidden={true} className={kyb.stickyDiv} style={{marginLeft: isFHD ? '-50vw' : isMac ? '-45vw' : isDell || isSmallReso ? '-38vw' : '', zIndex: 5}}>
                    <div style={{display: isMobile ? 'block' : 'flex', 
                    justifyContent: 'center'
                    // marginLeft: isMobile ? '' :  isFHD ? '350px' : isHighReso ? '100px' : '50px'
                    }}>
                        <div align={isMobile ? 'center' : ''} 
                            style={{
                                    // marginRight: isMobile ? '' : '270px', 
                                    paddingTop: isMobile ? '' : '8px', 
                                    paddingBottom: isMobile ? '10px' : '', color: '#fff'}}>
                        Before submitting, make sure all the information is correct and accurate.
                        </div>

                        <div align={isMobile ? 'center' : ''} style={{marginLeft: isDell || isSmallReso ? '15vw' : '25vw'}}>
                            <button className={`btn--${props.segments} btn-height`} style={{width: isMobile ? '100%' : ''}}
                                onClick={() => submitApplication()}>
                                Resubmit Application
                            </button>
                        </div>
                    </div>
            </div>

        </div>
    )
}

export default MainReview