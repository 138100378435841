import React, {useEffect, useState, useRef} from 'react';
import {Checkbox, message, Modal, Table, Typography} from 'antd';
import feeStyle from './style';
import settingServices from '../../account_settings/settingServices';

const PermissionsModal = (props) => {
    const {
        isPermissionsModalVisible,
        setIsPermissionsModalVisible,
        pUid
    } = props;
    const fee = feeStyle();
    const userType = localStorage.getItem('userType');
    const parentID = (userType === 'CO' || userType === "ME" || userType == "SW") ? localStorage.getItem('userId') : pUid;
    const CORE_SETTINGS = [
        'dashboard_access',
        'integration_access',
        'payment_request_access',
        'payout_access',
        'reconciliation_access',
        'settings_access',
        'multiwallet_access',
    ]

    const permissionsSnapshot = useRef([]);
    const [aliasPermissions, setAliasPermissions] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const segments = localStorage.getItem('segments');
    const isMakerApproverEnabled = props.isMakerApproverEnabled;

    async function loadAliasPermissions() {
        try {
            let response = await settingServices.getAliasPermissions(parentID);
            const permissionsData = response.data;

            permissionsSnapshot.current = permissionsData.map(alias => {return {...alias}});
            setAliasPermissions(permissionsData);
        } catch (err) {
            message.error(err.message);
        }

        setIsTableLoading(false);
    }


    async function savePermission(alias_id, setting_name, isChecked) {
        let params = {
            alias_id,
            'name': setting_name,
            'value': isChecked ? "True" : "False",
            'type': 'bool'
        }

        try {
            let response = await settingServices.saveAliasPermissions(parentID, params);
            if (response.status === 'failed') {
                throw new Error(response.message);
            }

        } catch (err) {
            message.error(err.message);
        }
    }


    function checkPermissionsBeforeSaving() {
        for (const alias of aliasPermissions) {
            const snapshotCounterpart = permissionsSnapshot.current.find(snapshot => snapshot.alias_id === alias.alias_id);

            for (const setting_name of Object.keys(alias)) {
                if (snapshotCounterpart[setting_name] !== alias[setting_name]) {
                    savePermission(alias.alias_id, setting_name, alias[setting_name]);
                }
            }
        }
    }


    function handleCheckChange(alias_id, setting_name, isChecked) {
        let newPermissions = aliasPermissions.map(alias => {return {...alias}});
        let affected_alias = newPermissions.find(alias => (alias.alias_id === alias_id));
        affected_alias[setting_name] = isChecked;

        let wereCorePermissionsEmptied = ! CORE_SETTINGS.map(setting_name => affected_alias[setting_name])
                                                        .some(setting_val => !!setting_val);
        if (wereCorePermissionsEmptied) {
            message.error("At least one box must be checked for the fields not marked optional.");
            return;
        }

        if(setting_name === 'approver_access' && isChecked){
            affected_alias['maker_access'] = false;
        }

        if(setting_name === 'maker_access' && isChecked){
            affected_alias['approver_access'] = false;
        }

        setAliasPermissions(newPermissions);
    }


    const permissionsColumns = [
        {
            title: (<Typography style={{padding: '34px 100px', height: 90}} >Name</Typography>),
            key: 'name',
            width: '20vw',
            fixed: 'left',
            render: (text, alias_user) => {
                return (
                    <div>
                        <div><b>{alias_user.full_name}</b></div>
                        <div style={{color: "#505050"}}>{alias_user.email}</div>
                    </div>
                )
            }
        },
        {
            title: "Dashboard",
            key: 'dashboard_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.dashboard_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'dashboard_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Integration",
            key: 'integration_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.integration_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'integration_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Multiwallet",
            key: 'multiwallet_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.multiwallet_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'multiwallet_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Payment Request",
            key: 'payment_request_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payment_request_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_request_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Payout",
            key: 'payout_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payout_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payout_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Reconciliation",
            key: 'reconciliation_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.reconciliation_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'reconciliation_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Settings",
            key: 'settings_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.settings_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'settings_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Test Mode <i>(optional)</i>
                </span>
                ),
            key: 'test_mode_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.test_mode_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'test_mode_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Settlement Reports <i>(optional)</i>
                </span>
                ),
            key: 'settlement_reports_visibility',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.settlement_reports_visibility}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'settlement_reports_visibility', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Alter QR Settings <i>(optional)</i>
                </span>
                ),
            key: 'qr_settings_alteration',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.qr_settings_alteration}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'qr_settings_alteration', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: (
                <span style={{fontSize: 10}}>
                    Create Payment Links <i>(optional)</i>
                </span>
                ),
            key: 'payment_link_creation',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.payment_link_creation}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'payment_link_creation', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        ...isMakerApproverEnabled?[{
            title: "Approver",
            key: 'approver_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.approver_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'approver_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        },
        {
            title: "Maker",
            key: 'maker_access',
            width: 100,
            align: 'center',
            render: (text, alias_user) => {
                return (
                    <div style={{textAlign: 'center'}} >
                        <Checkbox
                            checked={alias_user.maker_access}
                            onChange={(e) => handleCheckChange(alias_user.alias_id, 'maker_access', e.target.checked)}
                            disabled={alias_user.has_merchant_bias}
                        />
                    </div>
                )
            }
        }]:[],
    ];


    useEffect(() => {
        if (! isPermissionsModalVisible) {
            return;
        }

        loadAliasPermissions();
    }, [isPermissionsModalVisible]);


    return(
        <Modal
            centered
            width={'90vw'}
            visible={isPermissionsModalVisible}
            onCancel={() => {
                setIsPermissionsModalVisible(false);
            }}
            footer={null}
        >
        <div style={permissions_modal_styles.headerText}>
            Edit Alias Permissions
        </div>
        
        <div>
            <Table
                style={permissions_modal_styles.table}
                size="middle"
                pagination={false}
                dataSource={aliasPermissions}
                columns={permissionsColumns}
                rowKey={(alias) => alias.id}
                loading={isTableLoading}
                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
                scroll={{x: '1300'}}
                width={532}
            />
        </div>

        <div style={permissions_modal_styles.buttonsDiv}>
            <button
                className={`outline-btn--${segments}`}
                onClick={() => {
                    setIsPermissionsModalVisible(false);
                }}
                style={{width: '', margin: '0 8px'}}
            >
                Cancel
            </button>
            <button
                className={`btn--${segments}`}
                onClick={() => {
                    checkPermissionsBeforeSaving();
                    message.success("Settings saved.", 2);
                    setIsPermissionsModalVisible(false);
                }}
                style={{width: '', }}
            >
                Save
            </button>
        </div>
    </Modal>
    )
}

const permissions_modal_styles = {
    buttonsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    headerText: {
        fontSize: 'large'
    },
    footerText: {
        fontSize: 'medium',
        textAlign: 'right',
        fontStyle: 'italic',
        paddingRight: '5px'
    },
    table: {
        margin: '10px 0px 30px 0px',
    },
    inactive: {
        width: "60px",
        margin: '0px 6px',
        backgroundColor: 'rgb(245, 245, 245)',
        border: '1px solid rgb(192, 192, 192)',
        fontSize: "x-small",
        padding: "2px"
    }
}

export default PermissionsModal;