import React from 'react'
import {Alert, Drawer, InputNumber,Select,Input, Checkbox, Button, Divider, Spin, message, Typography} from 'antd'
import { BANKS_INSTAPAY, BANKS_PESONET, PAYMENT_MODE, BANKS } from '../constants/constants';
import OTPInput, { ResendOTP } from "otp-input-react";
import Payoutips from '../../static/icons/payout.svg'
import axios from 'axios'
import * as Style from './payout_drawer_style'
import PayoutModal from './payout_modal'
import { isMobile } from 'react-device-detect';
import { getUserPayoutBank } from './api/getUserPayoutBank';
import { getLatestBalance } from '../../screens/Transfer/api';

const { Option } = Select;
const payout_enabled= localStorage.getItem("payout_enabled");
const defined_wallet_settings = JSON.parse(localStorage.getItem('defined_wallet_settings'));
const payout_type = defined_wallet_settings && defined_wallet_settings["payout_type"] ? defined_wallet_settings["payout_type"] : 0;
const max_timer = 30;

export function openPayoutDrawer(is_payout_restricted){
    this.setState({drawerVisible: true})
    this.setState({is_payout_restricted});
}

class PayoutDrawer extends React.Component {

    componentDidMount(){
        let fname = localStorage.getItem("firstName")
        let lname = localStorage.getItem("lastName")
        this.setState({full_name: fname+" "+lname});

        
        this.fetchBanks()
        this.clearFirelds();

        // only fetch user restricted bank if user is not admin
        let isAdmin = localStorage.getItem("userType") === "AD";
        !isAdmin && this.fetchUserRestrictedPayoutBank();
    }

    constructor(props) {
        super(props)

        this.state = {
            drawerVisible: false,
            value: 1,
            size: 'large',
            amount: '',
            bankId: 0,
            accountName: '',
            accountNumber: '',
            mode: '',
            response: this.props.response,
            alertVisibility: false,
            alertMessage: '',
            onProcess: false,
            limitModal: false,
            otp_uid: '',
            payout_uid: '',
            otp_finished: false,
            OTP: '',
            otp_disabled: true,
            counter: 0,
            resend: true,
            spin: false,
            bank_details: [],
            primary: false,
            save_bank: false,
            full_name: '',
            email_otp: false,
            mobile: '',
            open_modal: false,
            otpLoading: false,
            otpMax: false,
            segments: localStorage.getItem('segments'),
            is_payout_restricted: false,
            restricted_banks: [],
            has_cashbux_privilege: localStorage.getItem('has_cashbux_privilege'),
            is_bank_approved: localStorage.getItem('is_bank_approved'),
            fees: [],
        }

        openPayoutDrawer = openPayoutDrawer.bind(this)
    }

    isSelectedBank(){
      if(this.state.bank === 'GCash (G-Xchange Inc.)' || this.state.bank === 'Paymaya' || this.state.bank === 'DCPay Philippines, Inc.(Coins.ph)' && this.state.bank) {
          return false;
      }
      return true;
    }

    handlePayout(){
        if(localStorage.getItem("Level") == 0 || localStorage.getItem("Level") == null){
          message.destroy();
          message.error('Complete your profile first')
        }
        else if(payout_enabled != "true" || payout_type == 1){
          message.destroy();
          message.error('Payout is temporarily disabled')
        }
        else{
          this.showDrawer()
        }
    }

    emailOTP = async () => {
      this.setState({
        otpLoading: false,
      });
        let yourConfig = {
          headers: {
              Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
        try{
            let response = await axios.get(
                process.env.REACT_APP_API_URL + "/api/email_otp/?subject=payout",
                yourConfig
            );
            if(response.data.status == "success"){
              clearInterval(this.interval);
              this.setState({spin: false, counter: 120, email_otp: true, otpLoading: false, otpMax: false})
    
            }else if(response.data.code == "mobile"){
              this.setState({ otp_finished: true, spin: false, email_otp: true, otpLoading: false});
            }
        }
        catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            this.setState({
              otpLoading: false,
            });
        }
    }

    fetchUserRestrictedPayoutBank = async () => {
      this.setState({ isDataLoading: true });
      try {
        const response = await getUserPayoutBank();
        const banks = await response.banks.map((bank) => bank.bank_name);
        this.setState({ restricted_banks: banks });
      } catch (error) {
        console.log(error);
      }
    };

    fetchBanks = async() => {
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        } 
    
        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"
    
        let res = await fetch(url, yourConfig);
    
        if (res.ok) { 
            let json = await res.json();
            this.setState({bank_details: json})
        }
        else {
            alert("HTTP-Error: " + res.status);
        }

    }
    
    fetchOTP = async() => {
        this.setState({
          onProcess: true,
          spin: true
        })
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try{
          let response = await axios.get(
              process.env.REACT_APP_API_URL + "/api/otp/",
              yourConfig
          );
          if(response.data.status == "success"){
            this.setState({spin: false, counter: 120, mobile: response.data.mobile, showAlert: false, fees: []})
            this.setState({ otp_uid: response.data.uid});
            this.startTimer()
          }
          else if(response.data.code == "attempts"){
            this.setState({spin: false, showAlert: true, alertMessage: <div>{response.data.message} <div>Please try again on {response.data.unblock}</div><div></div></div>, counter: 0})
            this.emailOTPmode();
          }
          else{
            this.setState({spin: false, showAlert: true, alertMessage: response.data.message})

          }
        }
          catch(error){
              // if((error.response)&&(error.response.status == 401)){
              //     Handle401()
              // }
          }
      
    }

    emailOTPmode = () =>{
      this.setState({ otp_uid: true, otpMax: true});
      setTimeout(() => {
        this.setState({showAlert: false})
      }, 3000);
    }

    startTimer(){
        try{
          clearInterval(this.interval);
        }catch(err){}
  
        this.setState({counter: max_timer});
        this.interval = setInterval(() => {
          if(this.state.counter>0){
            this.setState(prevState => ({
                counter: prevState.counter - 1
            }));
          }else{
            clearInterval(this.interval);
          }
        }, 1000);
    }
    onOTPChange(val) {
        if (val.length == 4) {
          this.setState({otp_disabled: false});
        } else if (val.length < 4) {
          this.setState({otp_disabled: true});
        }
        this.setState({OTP: val})
      }

     autoFormat = (str) => {
        str = str.toString();
        if (str !== null && str.indexOf(".") > -1) {
            str = str.slice(0, (str.indexOf(".")) + 2 + 1);
            return parseFloat(str.replace(/[^\d\.]/g, ''));
        }
        return str;
      }
    
      setMode(e){
        const bank = this.state.bank;
        const value = this.autoFormat(e.target.value);
        if(value >= 50001 && bank != "UnionBank of the Philippines"){
          this.setState({
            mode: "PesoNet"
          })
        } else if(value <= 50000 && bank != "UnionBank of the Philippines"){
          this.setState({
            mode: "Instapay"
          })
        }
    
        else{
          this.setState({
            mode: 'UnionBank'
          })
        }
        this.setState({
          amount : value,
        })
     
    }

    submit = async(payout_uid) => {
        const { bankId, amount, accountName, accountNumber, bank, mode } = this.state
      
        if(amount <= 0){
          this.setState({
            showAlert: true,
            alertMessage: 'Amount must be Php 1.00 and up',
            spin: false
          })
        }
        else if (accountName.length > 30) {
          this.setState({
            showAlert: true,
            alertMessage: 'Account name must be 30 characters or below',
            spin: false,
          })
        }
        else{
          this.setState({
            onProcess: true,
            spin:true,
          })
          let params = {
            bank_account_id: bankId,
            amount: amount,
            account_name: accountName.replace(/[^\w\s]/gi, ''),
            account_number: this.isSelectedBank()? accountNumber : this.state.bank_details.length > 0? accountNumber : '0'+accountNumber,
            mode: mode,
            bank: bank,
            payout_uid: payout_uid
          }
                  
          const yourConfig = {
            headers: {
               Authorization: "Token " + localStorage.getItem("jwtToken")
            }
          }
          let res = await axios.post(process.env.REACT_APP_API_URL + '/api/payouts/create/',params, yourConfig)
          if(res.data.status == "success"){
             this.setState({
              //  drawerVisible: false,
               open_modal: true
             })
          }
          else{
            this.setState({
              showAlert: true,
              alertMessage: res.data.message,
              onProcess: false,
              otp_uid: '',
              spin: false
            })
            setTimeout(() => {
              this.setState({
                 showAlert: false,
                 otp_uid: '',
                 onProcess: false,
                 email_otp: false,
                 spin: false,
                 otpDisable: true,})
            }, 3000);
          }
        }
    
    }

    clearFirelds = () => {
      this.setState({
        showAlert: false,
        amount: '',
        bank: '',
        accountName: '',
        accountNumber: '',
        otp_uid: '',
        onProcess: false,
        email_otp: false,
        spin: false,
        otpDisable: true,
        fees: [],
      });
    }

    submitOTP = async() => {
      this.setState({
        otpLoading: true,
      });
        let params
        let url
        if(this.state.email_otp){
          url = process.env.REACT_APP_API_URL + "/api/email_otp/"
          params = {
            code: this.state.OTP
          }
        }
      
        else {
          url = process.env.REACT_APP_API_URL + "/api/otp/"
          params = {
            uid : this.state.otp_uid,
            code: this.state.OTP,
            otp_type: 1
          }
        }
      
      
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
        let response = {}
        try{
            response = await axios.post(url,params,yourConfig
            );
            if(response.data.status == 'success'){
              this.setState({otp_finished: true, payout_uid: response.data.trans_uid, otpLoading: false, OTP: '', otp_disabled: true});
              this.submit(response.data.trans_uid);
            }
            this.setState({
              onProcess: false
            })
        }
        catch(error){
            // if((error.response)&&(error.response.status == 401)){
            //     Handle401()
            // }
            if(error.response.data.code == "attempts"){
              this.setState({spin: false, showAlert: true, alertMessage: <div>{error.response.data.message} <div>Please try again on {error.response.data.unblock}</div><div></div></div>, otpLoading: false, OTP: '', otp_disabled: true})
            }
            
            else{
              this.setState({spin: false, showAlert: true, alertMessage: error.response.data.message, otpLoading: false, OTP: '', otp_disabled: true})
            }
      
            this.setState({
              onProcess: false
            })
            this.setState({
              otpLoading: false,
            });
        }
        
    }
    
    setBank(value){
        const amount = this.state.amount;
        if((this.state.bank_details.length == 0 ? value : value[0]) == "UnionBank of the Philippines" && amount == 0){
          this.setState({
            mode: "UnionBank"
          })
        }
    
        else if(amount <= 50000 && (this.state.bank_details.length == 0 ? value : value[0]) != "UnionBank of the Philippines"){
          this.setState({
            mode: "Instapay"
          })
    
          if(BANKS_INSTAPAY.includes((this.state.bank_details.length == 0 ? value : value[0]))){
            this.setState({
              mode: "Instapay"
            })
          }
    
          else if (BANKS_PESONET.includes((this.state.bank_details.length == 0 ? value : value[0]))){
            this.setState({
              mode: "PesoNet"
            })
            
          }
    
          else {
            this.setState({
              mode: "UnionBank"
            })
          }
        }
        
        else {
          if(BANKS_PESONET.includes((this.state.bank_details.length == 0 ? value : value[0]))){
            this.setState({
              mode: "PesoNet"
            })
          }else if((this.state.bank_details.length == 0 ? value : value[0])=="UnionBank of the Philippines"){
            this.setState({
              mode: "UnionBank"
            })
          }
    
          else{
            this.setState({
              mode: ""
            })
    
          }
        }
        this.setState({
          bank: this.state.bank_details.length == 0 ? value : value[0],
          accountName: this.state.bank_details.length == 0 ? '' : value[1],
          accountNumber: this.state.bank_details.length == 0 ? '' : value[2],
          primary: this.state.bank_details.length == 0 ? '' : value[3],
          bankId: this.state.bank_details.length == 0 ? '' : value[4]
        })

    }
    
    saveBank = async() => {
        this.setState({spin: true, bankId: 0})
        let params = {
          bank: this.state.bank,
          account_name: this.state.accountName,
          account_number: this.isSelectedBank()?this.state.accountNumber: '0'+this.state.accountNumber,
        }
        
        let yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
  
        let url = process.env.REACT_APP_API_URL + "/api/bank_accounts/"
        await axios.post(url,params, yourConfig).then(response => {
            this.setState({spin: false, showAlert: false, bankId: response.data.id})
            this.onNext()
          })
         .catch(error => {
          console.log(error)
            this.setState({spin: false, showAlert: true, alertMessage: error.response.data.message})
        })      
        
    }
    onSaveBank(val){
        this.setState({save_bank: val})
    }
    
    
    calculateDuration (){
        const minutes = Math.floor(this.state.counter/60).toString();
        const seconds = Math.floor(this.state.counter%60);
        let seconds_str = seconds.toString();
    
        if(this.state.counter == 0){
          clearInterval(this.interval);
        }
        if(seconds < 10){
            seconds_str = "0" +seconds.toString();
        }
    
        return (<b>{minutes + ":" + seconds_str}</b>)
    };

    onNext = async() => {
      const { bankId, amount, accountName, accountNumber, bank, mode } = this.state
    
      if(amount <= 0){
        this.setState({
          showAlert: true,
          alertMessage: 'Amount must be Php 1.00 and up',
          spin: false
        })
      }
      else if (accountName.length > 30) {
        this.setState({
          showAlert: true,
          alertMessage: 'Account name must be 30 characters or below',
          spin: false,
        })
      }
      else{
        this.setState({
          onProcess: true,
          spin:true,
        })
        let params = {
          bank_account_id: bankId,
          amount: amount,
          account_name: accountName.replace(/[^\w\s]/gi, ''),
          account_number: this.isSelectedBank()? accountNumber : this.state.bank_details.length > 0? accountNumber : '0'+accountNumber,
          mode: mode,
          bank: bank,
        }
                
        const yourConfig = {
          headers: {
             Authorization: "Token " + localStorage.getItem("jwtToken")
          }
        }
        let res = await axios.post(process.env.REACT_APP_API_URL + '/api/payouts/verify/',params, yourConfig)
        
        if(res.data.status == "success"){
           this.setState({fees: res.data.data.fees, spin: false});
        } else {
          this.setState({
            showAlert: true,
            alertMessage: res.data.message,
            onProcess: false,
            otp_uid: '',
            spin: false
          })
          setTimeout(() => {
            this.setState({
               showAlert: false,
               otp_uid: '',
               onProcess: false,
               email_otp: false,
               spin: false,
               otpDisable: true,})
          }, 3000);
        }
      }
    }
    
    renderPesonet(){
        return(
          <div style={{marginBottom: 16}}>
            <div style={{ fontWeight: '600', color: '#000000' }}>PesoNet</div>
            <div style={{color: '#909196'}}>Transactions before the 3PM cut-off are processed same banking day</div>
            <br/>
            <div style={{color: '#909196'}}>Transactions after cut-off or holidays are processed the next banking day.</div>
         </div>
        )
    }
    
    renderInstapay(){
        return(
          <div style={{marginBottom: 16}}>
              <div style={{ fontWeight: '600', color: '#000000' }}>Instapay</div>
              <div style={{color: '#909196'}}>Transaction limit is ₱50,000</div>
          </div>
        )
    }
    
    renderUnionBank(){
    
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>UnionBank of the Philippines</li>
            </ul>
          </div>
        )
    }
    
    renderNone(){
        return(
          <div>
            <ul style={{ width: '100%', marginLeft: '-20px', marginBottom: '20px' }}>
              <li style={{ fontWeight: '600' }}>Bank is unavailable</li>
            </ul>
          </div>
        )
    }

    renderPayoutData(label, value) {
      return(
        <div style={{ display: 'flex', gap: '24px', marginBottom: '12px' }}>
          <span style={{ width: '40%', fontWeight: '600' }}>{label}</span>
          <span style={{ width: '55%' }}>{value}</span>
        </div>
      )
    }
    
    render(){
        const { bank_details, is_payout_restricted, restricted_banks } = this.state;
        let banks = [];

        if (bank_details && bank_details.length !== 0) {
          banks = bank_details;
        } else if (is_payout_restricted) {
          banks = restricted_banks;
        } else {
          banks = BANKS;
        }

        const banksPesonet = BANKS_PESONET;
        const banksInstapay = BANKS_INSTAPAY;

        banks.sort();

        let bank = banks.length > 0 && banks.map((item, i) => {
            return (
              this.state.bank_details.length != 0 ?

              <Option key={i} value={[item.bank, item.account_name, item.account_number, item.is_primary, item.id]}>{item.bank}</Option>
              :
              <Option key={i} value={item}>{item}</Option>
            )
        }, this)
        return(
            <div style={{position: 'absolute'}}>
                <PayoutModal openModal={this.state.open_modal} primary={this.state.primary} 
                              bank={this.state.bank} amount={this.state.amount} 
                              accountName={this.state.accountName} accountNumber={this.state.accountNumber} onClose={()=>this.setState({drawerVisible: false})} />
                <Drawer
                    style={{overflowY: 'hidden'}}
                    placement="right"
                    width={ isMobile? '100%' : "360px"}
                    closable={true}
                    onClose={()=>{this.setState({drawerVisible: false})}}
                    visible={this.state.drawerVisible}
                    afterVisibleChange={this.clearFirelds}
                    >
                        <div style={{padding: '20px', borderRadius: '30px 0px 0px 20px'}} hidden={this.state.otp_uid || this.state.fees.length > 0}>
    
                            <div style={Style.titleStyle}>Payout</div>
    
                            <Alert message={this.state.alertMessage} type="error" showIcon style={{ display: this.state.showAlert ? "block" : "none", borderLeft: '4px solid red', marginBottom: '20px' }}/>
    
                            <div style={Style.newTextStyle}>Amount</div>

                            {/* <InputNumber 
                              data-test='input-amount'
                              size="large" 
                              style={{ 
                                width: '100%', 
                                marginBottom: '20px' 
                              }} 
                              onChange={
                                (ev) => this.setMode(ev)
                              } 
                              value={this.state.amount}
                              
                              /> */}

                            <Input
                                data-test='input-amount'
                                size='large'
                                addonBefore="₱"
                                type='number'
                                value={this.state.amount}
                                style={{ 
                                  fontSize: 16,
                                  margin: '4px 0px 0px 0px',
                                  color: '#2B2D33',
                                  // border: '.5px solid #B7B9C0', 
                                  color: '#2B2D33',
                                  borderRadius: 4 }}
                                onChange={ (ev) => this.setMode(ev)}
                                onWheel={(e) => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                              />
                
                            <div style={{ margin: '20px 0' }}> <span style={Style.subHeaderStyle}>Select saved bank</span> </div>
    
                            <div style={Style.newTextStyle}>Destination</div>
                            <Select
                                data-test='input-bank'
                                showSearch
                                onChange={(ev)=>this.setBank(ev)}
                                placeholder="Choose a Bank"
                                style={{ width: '100%', marginBottom: '10px', marginTop: 4 }}
                                size="large"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                value={this.state.bank? this.state.bank : 'Choose a Bank'}
                            >
                            {bank}
    
                            </Select>
                        
                            {
    
                            this.state.mode === "Instapay" ?
                            
                            this.renderInstapay()
    
                            :
    
                            this.state.mode === "PesoNet" ?
    
                            this.renderPesonet()
    
                            :
    
                            this.state.mode === "UnionBank" ?
    
                            this.renderUnionBank()
    
                            :
    
                            this.renderNone()
    
                            }
    
                            <div hidden={this.state.bank_details.length != 0}>
                            <div style={Style.newTextStyle}><span>Account name</span></div>
                            <Input 
                              data-test='input-account-name'
                              value={this.state.accountName} 
                              onChange={
                                (accountName) => 
                                  this.setState(
                                    { 
                                      accountName: accountName.target.value 
                                    }
                                  )
                                } 
                                size="large" 
                                style={{ width: '100%', marginBottom: '20px' }} 
                              />
                            <div style={Style.newTextStyle}><span>{this.isSelectedBank()?'Account number':'Mobile number'}</span></div>
                            <div style={{position: 'relative'}}>
                              <Input 
                              data-test='input-account-number'
                              type="number" 
                              value={this.state.accountNumber} 
                              onChange={
                                (accountNumber) => 
                                  this.setState(
                                    { 
                                      accountNumber: accountNumber.target.value 
                                    }
                                  )
                                } 
                              size="large" 
                              style={{ width: '100%', marginBottom: '20px', paddingLeft: this.isSelectedBank()? '' : 60}} 
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                              />

                              {this.isSelectedBank()? null : <Typography style={Style.prefix} >+63 | </Typography>}
                            </div>
    
                            <div style={{marginBottom: 32}}>
                                <Checkbox checked={this.state.save_bank} onChange={(e) => {this.onSaveBank(e.target.checked)}}/> <span style={{fontSize: '16px', color: 'rgba(43, 45, 51, 0.8)', paddingLeft: 8}}>Save Bank Account</span>
                            </div>
                            </div>
    
    
                            <div hidden={this.state.bank_details.length == 3 || this.state.bank_details.length == 0}>
                            {(this.state.is_bank_approved && this.state.has_cashbux_privilege) 
                              ? "" 
                              : <>
                                  <Divider style={{display: 'flex', alignItems: 'center', margin: '32px 0', padding: '0 52px'}}>
                                    <div align="center" style={Style.subHeaderStyle} style={{ color: '#9A9EA9', opacity: '0.8', fontSize: 16}}>
                                      OR
                                    </div>
                                  </Divider>
                                  <div>
                                    <button style={Style.linkBank} onClick={() => {window.open('/link_bank')}}>
                                      Link New Bank Account
                                    </button>
                                  </div>
                                </>
                            }
                            </div>
    
                            <div style={{marginBottom: '10px'}} hidden={this.state.accountName == "" || this.state.bank_details.length == 0}>
    
                            <div style={Style.subHeaderStyle}>Account Details</div>
    
                            <div style={Style.accountDetails}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div><b>{this.state.accountName}</b></div>
                                <div style={Style.primaryStyle} hidden={!this.state.primary}>Primary</div>
                                </div>
                                <div style={{color: '#2B2D33', fontSize: '16px'}}>
                                Account No: {this.state.accountNumber}
                                </div>
                            </div>
    
                            </div>
    
                            <div hidden={this.state.spin}>   
                            <button 
                              data-test='next-button'
                              className={`btn--${this.state.segments}`}
                              style={!this.state.mode ||
                                !this.state.amount ||
                                !this.state.accountName || 
                                !this.state.accountNumber ||
                                !this.state.bank || 
                                this.state.onProcess ? Style.otpDisableBtn : 
                                Style.otpSubmitBtn} 
                                onClick={() => {this.state.save_bank ? this.saveBank() : this.onNext() }} 
                                size="large" type="primary" block 
                                disabled={!this.state.mode ||
                                !this.state.amount ||
                                !this.state.accountName || 
                                !this.state.accountNumber ||
                                !this.state.bank || 
                                this.state.onProcess}> Next </button>
                            </div>
    
                            <div style={Style.tipsDiv} hidden>
                                <div>
                                <img src={Payoutips} />
                                </div>
                                <div>
                                <div style={Style.subHeaderStyle}>QUICK TIP</div>
                                <div style={{color: '#909196', marginTop: 4,}}>
                                    Customize your Linked Bank Accounts through your <a href="/account_settings#bank_account" style={{color: '#F5922F', fontWeight: 600}}>Settings</a>.
                                </div>
    
                                </div>
                            </div>
    
                            <div align='center' style={{marginTop: 30}} hidden={!this.state.spin}>
                            <Spin size="large"/>
                            </div>
                        </div>
                        
    
                        <div style={{padding: '20px'}} align='center' hidden={!this.state.otp_uid || this.state.fees.length > 0}>
                            <div style={Style.spielOtp} align='center'>
                            <div style={Style.otpHeader}>One-time PIN</div>
                            <div style={Style.emailSent} hidden={true}>OTP PIN has been sent to your email</div>
    
                            <div style={Style.sDesc}>
                                To proceed, a 6-digit code will be sent via 
                                {this.state.email_otp ? 
                                ' email on your registered email address' :
                                
                                <div>
                                SMS on your registered number ending in <b>{this.state.mobile? this.state.mobile.substr(this.state.mobile.length - 4) : ''}</b>.
                                </div>
                                }
                            </div>
                            </div>
    
                            <Alert message={this.state.alertMessage} type="error" showIcon style={{ display: this.state.showAlert  ? "block" : "none", borderLeft: '4px solid red', marginTop: '20px', marginBottom: '20px' }}/>
    
                            <div style={Style.inputDiv} align='center'>
                            <OTPInput
                                value={this.state.OTP}
                                onChange={(e) => this.onOTPChange(e)}
                                autoFocus
                                OTPLength={6}
                                style={{marginLeft: 8 }}
                                otpType="number"
                                inputStyles={{
                                    height: 70,
                                    width: 34,
                                    fontSize: "32px",
                                    color: "#2b2d33",
                                    fontWeight: "600",
                                    background: "#E6EAF0",
                                    boxSizing: "border-box",
                                    border: "1px solid #d1d5dd",
                                    borderRadius: "4px"
                                }}
                                disabled={this.state.otpMax}
                            />
                        </div>
    
                        <div style={{marginTop: 30}} align="center">
                            <div hidden={this.state.email_otp}>
                            <div style={{marginTop: '16px'}}>
                                <button
                                    onClick={() => { this.fetchOTP() }} 
                                    disabled={this.state.counter > 0 || this.state.otpMax}
                                    className={`outline-btn--${this.state.segments}`}>Resend Code</button>
                            </div>
    
                            <div style={{marginTop: '16px'}} hidden={this.state.counter == 0}>
                                <b>"Resend Code"</b> will be enabled after {this.calculateDuration()}
                            </div>
                            </div>
    
    
                        <div style={Style.submitDiv}>
                            <button
                                style={!this.state.otp_disabled ? Style.otpSubmitBtn : Style.otpDisableBtn}
                                disabled={this.state.otp_disabled}
                                onClick={() => this.submitOTP()}
                                loading={this.state.otpLoading}
                                className={`btn--${this.state.segments}`}
                            >
                            Submit
                            </button>
                        </div>
                        
                        <div hidden={this.state.email_otp}>
                            <div style={{marginTop: 32}}>
                                <Divider style={{display: 'flex', alignItems: 'center', margin: '30px 0'}}>
                                    OR
                                </Divider>
                            </div>
    
                            <div style={{marginTop: '16px'}}>
                            <a className={`link-text--${this.state.segments}`} style={Style.otpEmail} onClick={() => {this.emailOTP()}}>Send OTP Code via Email</a>
                            </div>
    
                        </div>
    
    
                        <div hidden={!this.spin} align='center'>
                            <Spin size="large" />
                        </div>
    
                        </div>
                    </div>

                    <div style={{padding: '20px'}} hidden={this.state.fees.length == 0}>
                      <div style={Style.titleStyle}>Payout Details</div>
                      {this.renderPayoutData("Amount", `₱ ${this.state.amount}`)}
                      {this.renderPayoutData("Bank", this.state.bank)}
                      {this.renderPayoutData("Account Name", this.state.accountName)}
                      {this.renderPayoutData("Account Number", this.state.accountNumber)}
                      {
                        this.state.fees.map((item, key) => {
                          return (this.renderPayoutData(item.name, `₱ ${item.value}`))
                      })}

                      <div style={{ marginTop: '30px' }}>   
                        <button 
                          data-test='next-button'
                          className={`btn--${this.state.segments}`}
                          style={Style.otpSubmitBtn} 
                          onClick={() => {
                            this.fetchOTP();
                          }} 
                          hidden={this.state.spin}
                          size="large" type="primary" block >
                            Next 
                        </button>
                        <div align='center' style={{marginTop: 30}} hidden={!this.state.spin}>
                          <Spin size="large"/>
                          </div>
                      </div>
                    </div>
                </Drawer>
            </div>
        )
    }
    
}
export default PayoutDrawer
