import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch, message} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY, POSITION} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'

const PrimaryIDType = { 
    'SSS': 'SSS',
    'GSIS': 'GSIS',
    'DRL': `Driver's License`,
    'PAS': 'Passport',
    'IBP': 'Integrated Bar of the Philippines ID',
    'PRC': 'PRC ID',
    'NBI': 'NBI Clearance',
    'SID': 'School ID',
    'PSID': 'PhilSys ID',
}

const SecondaryIDType = { 
    'AICR': 'Alien Certificate of Registration Identity Card',
    'CID': ' Company ID',
    'GOID': 'Government office and GOCC ID',
    'NCDA': 'National Council on Disability Affairs ID',
    'OFW': ' OFW ID',
    'OWWA': 'Overseas Workers Welfare Administration ID',
    'PICB': 'PhilHealth Insurance Card ng Bayan',
    'PID': 'Postal ID',
    'SBID': 'Seaman’s Book',
    'TIN': 'TIN ID',
    'VID': `Voter's ID`,
    'SCC': 'Senior Citizen Card'
}

const ReviewSignatoryInfo = props => {
    let datas = props.datas
    let segments = props.segments
    const userRole = localStorage.getItem("userType")
    const kyb = enterpriseStyle()
    const [signatories, setSignatories] = React.useState(datas ? datas.signatories : [])
    const [toggleOn, SetToggleOn] = React.useState(true)
    let kybStep = localStorage.getItem("KYBStep")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SW', 'SD', 'BS']
    const createRole = ['AD', 'SD']
    const hiddenSteps = [8, 9, 10, 11]
    
    let kybLevel = localStorage.getItem("Level")
    
    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 4)
        }
    }

    React.useEffect(() => {
        submitFailed(datas ? !datas.step_4_has_error : false)
    },[])

    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                border: toggleOn && 
                        adminRole.includes(userRole)
                        ? '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                ''
            }
            
            <div className="bottom-32 twoCol" ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>Signatory Information</div>

                <div hidden={(['CO','SW'].includes(localStorage.getItem('userType')) && hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(4)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            {
                signatories.map((item, key) => {
                    return(
                        <div style={{marginBottom: '16px'}}>
                            <div className={kyb.headerStrong}>
                                Signatory {key+1}
                            </div>
                                
                            <div className={`${kyb.tableStyle} liveColor`}>
                                <div className="twoCol top-20">
                                    <div>
                                        Name:
                                    </div>
                                    <div className={kyb.valueText}>
                                    {
                                        item.middle_name ? 

                                        item.first_name+" "+item.middle_name+" "+item.last_name

                                        :

                                        item.first_name+" "+item.last_name
                                    }
                                    </div>
                                </div>
                                <div className="twoCol top-20">
                                    <div>
                                        Position:
                                    </div>
                                    <div className={kyb.valueText}>
                                        {POSITION[item.position]}
                                    </div>
                                </div>
                                {/* <div className="twoCol top-20">
                                    <div>
                                        Valid ID:
                                    </div>
                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                        <div>
                                            <a href={item.signature_attachment} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.signature_attachment} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                        </div>
                                    </div>
                                </div> */}
                                {
                                    item.ID_set === "a" ?
                                       <div>
                                            <div className="twoCol top-20">
                                                <div>
                                                    ID Type:
                                                </div>
                                                <div className={kyb.valueText}>
                                                    {PrimaryIDType[item.ID_type_main]}
                                                </div>
                                            </div>
                                            <div className="twoCol top-20">
                                                <div>
                                                    ID Number:
                                                </div>
                                                <div className={kyb.valueText}>
                                                    {item.ID_number_main}
                                                </div>
                                            </div>
                                            <div className="twoCol top-20">
                                                <div>
                                                    Front ID Photo:
                                                </div>
                                                <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                    <div>
                                                        <a href={item.front_id_attachment_main} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.front_id_attachment_main} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="twoCol top-20">
                                                <div>
                                                    Back ID Photo:
                                                </div>
                                                <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                    <div>
                                                        <a href={item.back_id_attachment_main} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.back_id_attachment_main} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                    </div>
                                                </div>
                                            </div>
                                       </div>
                                        : item.ID_set === "b" ?
                                            <div>
                                                <div className={kyb.headerStrong} style={{marginTop: '20px'}}>
                                                    1st Secondary ID
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Type:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {SecondaryIDType[item.ID_type_secondary_1]}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Number:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {item.ID_number_secondary_1}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Front ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.front_id_attachment_secondary_1} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.front_id_attachment_secondary_1} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Back ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.back_id_attachment_secondary_1} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.back_id_attachment_secondary_1} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={kyb.headerStrong} style={{marginTop: '20px'}}>
                                                    2nd Secondary ID
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Type:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {SecondaryIDType[item.ID_type_secondary_2]}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Number:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {item.ID_number_secondary_2}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Front ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.front_id_attachment_secondary_2} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.front_id_attachment_secondary_2} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Back ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.back_id_attachment_secondary_2} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.back_id_attachment_secondary_2} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={kyb.headerStrong} style={{marginTop: '20px'}}>
                                                    Expired Primary ID
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Type:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {PrimaryIDType[item.ID_type_expired]}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        ID Number:
                                                    </div>
                                                    <div className={kyb.valueText}>
                                                        {item.ID_number_expired}
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Front ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.front_id_attachment_expired} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.front_id_attachment_expired} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="twoCol top-20">
                                                    <div>
                                                        Back ID Photo:
                                                    </div>
                                                    <div className={kyb.valueText} style={{fontWeight: 200}}>
                                                        <div>
                                                            <a href={item.back_id_attachment_expired} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.back_id_attachment_expired} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        :
                                        <div className="twoCol top-20">
                                        <div>
                                            Valid ID:
                                        </div>
                                        <div className={kyb.valueText} style={{fontWeight: 200}}>
                                            <div>
                                                <a href={item.signature_attachment} target="blank" className={`text-${segments} medium-font-weight`}>View</a> {adminRole.includes(userRole) ?<span>| <a href={item.signature_attachment} target="blank" download className={`text-${segments} medium-font-weight`}>Download</a> </span>: ''}
                                            </div>
                                        </div>
                                    </div>
                                        
                                }


                            </div>
                        </div>
                    )
                })
            }
            
        </Card>
    )
}

export default ReviewSignatoryInfo