import React, { useEffect, useState } from 'react';
import {Divider, Modal, Spin} from 'antd';
import { makeStyles } from '@material-ui/core';
import CheckVerify from '../../../static/icons/check_verify.svg'
import CloseVerify from '../../../static/icons/close_verify.svg'
import axios from 'axios'
import {message} from 'antd'

const ComplianceRequirementsModal = (props) => {
    const modalStyle = makeStyles(theme => ({
        buttonsDiv: {
            display: 'flex',
            justifyContent: 'space-between',
            gap: '20px',
            marginTop: '24px'
        },
        cancelButton: {
            flexGrow: '1',
            color: '#0A315E',
            background: '#fff',
            border: '1px solid #0A315E',
            borderRadius: '4px',
            fontWeight: 'bold',
            cursor: 'pointer',
        },
        approveButton: {
            background: '#0A315E',
            border: '1px solid #0A315E',
            color: '#fff',
            borderRadius: '4px',
            fontWeight: 'bold',
            cursor: 'pointer',
            height: '40px',
            flexGrow: '1'
        },
        headerText: {
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#2B2D32',
        },
        descriptionText: {
            fontSize: '14px',
            margin: '0px',
            color: '#2B2D32',
        },
        requirementItem: {
            display: 'flex', 
            gap: '16px',
            marginTop: '16px',
        },
    })) ();

    const {
        visible,
        onCloseModal,
        onSubmit,
        type,
        submissionId,
        businessName,
        remarks,
        complianceProcess
    } = props;

    const [documents, setDocuments] = React.useState(null)
    const [businessType, setBusinessType] = React.useState("")

    useEffect(() => {
        if (visible && submissionId && submissionId != "") {
            getData();
        }
    }, [visible])

    async function getData(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let endpoint = complianceProcess == "kyb" ? `/api/admin/kyb/${submissionId}/documents/` : `/api/admin/kyc/${submissionId}/documents/`

        let response = await axios.get(
            process.env.REACT_APP_API_URL + endpoint, yourConfig)
        
        if(response.data.status == "success"){
            setDocuments(response.data.data.documents)
            setBusinessType(response.data.data.business_type)
        } else{
            message.error(response.data.message)
        }
    }

    function getDescription() {
        const hasMissingDocument = documents && documents.find((document) => {
            return document.path == null;
        })

        return hasMissingDocument ? <p className={modalStyle.descriptionText} style={{ marginTop: '16px' }}>We noticed that there are still <b style={{ color: '#0A315E' }}>missing requirements</b>.</p> : <></>
    }

    return(
        <Modal
            centered
            visible={visible}
            onCancel={onCloseModal}
            footer={null}
        >
            <div className={modalStyle.headerText}>
            { type == "reject" ? 
                `Are you sure you want to reject this merchant?`
                : 
                `Are you sure you want to approve this merchant?`
            }
            </div>
            <div style={{ marginTop: '20px' }}>
                <p className={modalStyle.descriptionText} style={{ fontWeight: 'bold', fontSize: '16px' }}>{businessName}</p>
                <p className={modalStyle.descriptionText}>{businessType} Requirements</p>
            </div>

            { documents ?
                <>
                    {getDescription()}

                    <div style={{ marginTop: '16px' }}>
                        {documents && documents.map((document, index) => (
                            <span className={modalStyle.requirementItem}>
                                { document.path != null ?
                                    <img src={CheckVerify} alt="#" style={{ height: '20px' }}/> 
                                    :
                                    <img src={CloseVerify} alt="#" style={{ height: '22px' }}/>
                                }
                                
                                {document.name}
                            </span>
                        ))}
                    </div>

                    <Divider style={{ margin: '16px 0px'}} />

                    <p style={{ fontWeight: 'bold', fontSize: '12px' }}>Remarks</p>
                    <p className={modalStyle.descriptionText}>{remarks}</p>

                    <div className={modalStyle.buttonsDiv}>
                        <button
                            onClick={onCloseModal}
                            className={modalStyle.cancelButton}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => { onSubmit(); }}
                            className={modalStyle.approveButton}
                        >
                            { type == "reject" ? `Reject` :  `Approve` }
                        </button>
                    </div>
                </>
                :
                <Spin size="small" style={{ marginTop: "24px", display: "block", textAlign: "center" }}/>
            }
            
        </Modal>
    )
}

export default ComplianceRequirementsModal;