import React, { useState, useEffect } from "react";
import MetaTag from "../../../meta_tag/meta_tag";
import { Typography, Button, Icon } from "antd";
import { history } from "../../../../store/history";
import GlobalPayoutFeesViewModel from "./global_payout_fees_viewmodel";
import PayoutFeesTable from "../components/payout_fees_table";
import PayoutFeeModal from "../components/payout_fee_modal";

const GlobalPayoutFees = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const {
    fetchGlobalPayoutFeeList,
    toggleGlobalPayoutFee,
    addGlobalPayoutFee,
    editGlobalPayoutFee,
    globalPayoutFeesData,
    isLoading,
    success,
    messageDisplay,
  } = GlobalPayoutFeesViewModel();

  function closeModal() {
    setOpenModal(false);
    // fetchGlobalPayoutFeeList(tempSearchQuery, currentPage);
  }

  function onSubmitModal() {
    setOpenModal(false);
    // setCurrentPage(1);
    // fetchGlobalPayoutFeeList(tempSearchQuery, 1);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else {
      if (
        localStorage.getItem("userType") == "ME" ||
        localStorage.getItem("userType") == "CO"
      ) {
        history.push("/dashboard");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "PF") {
        history.push("/recon/7_eleven");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      }
    }
  }, []);

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
      }}
    >
      <MetaTag title="Global Payout Fees" />
      <div>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "20px",
            color: "#2B2D33",
          }}
        >
          Global Payout Fees
        </Typography>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            type="primary"
            className="add-payout-fee-button"
            onClick={() => {
              setEditData(null);
              setOpenModal(true);
            }}
          >
            <Icon type="plus" />
            Add Global Payout Fee
          </Button>
        </div>
        <PayoutFeesTable
          fetchPayoutFeeList={fetchGlobalPayoutFeeList}
          togglePayoutFee={toggleGlobalPayoutFee}
          payoutFeesData={globalPayoutFeesData}
          isLoading={isLoading}
          success={success}
          messageDisplay={messageDisplay}
          openEditModal={openEditModal}
          isModalOpen={openModal}
        />
      </div>

      <PayoutFeeModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
        addPayoutFee={addGlobalPayoutFee}
        editPayoutFee={editGlobalPayoutFee}
        success={success}
        messageDisplay={messageDisplay}
        type={"Global"}
      />
    </div>
  );
};

export default GlobalPayoutFees;
