import { makeStyles } from '@material-ui/core';

const dbStyles = makeStyles(theme => ({
    main: {
        paddingBottom: '30px',
        paddingTop: '38px', 
        paddingRight: '42px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '3rem',
        width: '100%',
    },

    dashboardStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '28px',
        lineHeight: '32px',
        color: '#000000',
    },

    textStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#000000',
    },

    infoStyle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#000000',
        marginTop: '10px',
        width: '383px'
    },

    card: {
        display: 'flex', 
        width: '100%'
    },

    cardStyle: {
        width: '33.3%', 
        textAlign:'center', 
    },

    headerNumber: {
        fontSize: '30px',
        lineHeight: '56px',
        color: '#000000',
    },

    headerNumber2: {
        fontSize: '20px',
        lineHeight: '56px',
        color: '#000000',
    },

    todayStyle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '20px',
        color: '#000000',
    },

    chart: {
        gridTemplateColumns: '75%',
        padding: '10px', 
        marginTop: '20px', 
        width: '100%', 
        height: '350px',
        borderRadius: '10px'
    },

    saleStyle: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#2B2D33',
        marginBottom: '20px'
    },

    chartTestMode: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.89)',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    }
}))

export default dbStyles;