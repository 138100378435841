import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Table, DatePicker, Button, Icon, message } from 'antd';
import { Handle401 } from '../../handle401/handle401'
import dashboardStyles from '../../../static/css/Dashboard';

const AllBankLedger = props => {

  const {
    accountType,
  } = props;

  const [reportData, setReportData] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  
  const [startMoment, setStartMoment] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endMoment, setEndMoment] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [currentOffset, setCurrentOffset] = useState("0")
  const [nextOffset, setNextOffset] = useState(null)
  const [previousIsVisible, setPreviousIsVisible] = useState(false)
  const [nextIsVisible, setNextIsVisible] = useState(false)
  
  const db = dashboardStyles();

  const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const columns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      fixed: 'left',
    },
    {
      title: 'Bank Reference',
      dataIndex: 'bank_reference',
    },
    {
      title: 'Date',
      dataIndex: 'transaction_date',
    },
    {
      title: 'Amount',
      dataIndex: 'transaction_amount',
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
    },
    {
      title: 'Sender Reference ID',
      dataIndex: 'sender_reference_id',
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
    },
    {
      title: 'Description',
      dataIndex: 'transaction_description',
    },
    {
      title: 'Transaction Code',
      dataIndex: 'transaction_code',
    },
    {
      title: 'IBFT Status',
      dataIndex: 'ibft_status',
    },
    {
      title: 'Running Balance',
      dataIndex: 'running_balance',
      fixed: 'right',
    },
  ];

  const disabledStartDate = date => {
    const dateValue = date.endOf('day');
    const isFutureDate = dateValue > moment().endOf('day');

    if (endMoment) {
      const dateGreaterThanEndDate = dateValue > endMoment;
      const dateRangeGreaterThan31Days = dateValue < endMoment.clone().subtract(31, 'days');
      return isFutureDate || dateGreaterThanEndDate || dateRangeGreaterThan31Days;
    }
    return isFutureDate;
  };

  const disabledEndDate = date => {
    const dateValue = date.endOf('day');
    const isFutureDate = dateValue > moment().endOf('day');

    if (startMoment) {
      const dateLessThanStartDate = dateValue < startMoment;
      const dateRangeGreaterThan31Days = dateValue > startMoment.clone().add(31, 'days');
      return isFutureDate || dateLessThanStartDate || dateRangeGreaterThan31Days;
    }
    return isFutureDate;
  };
  
  const onStartChange = value => {
    if (value != null) {
      const dateValue = value.endOf('day');
      setStartMoment(dateValue)
      setStartDate(dateValue.format("YYYY-MM-DD"));
    } else {
      setStartMoment(null);
      setStartDate(null);
    }
  }

  const onEndChange = value => {
    if (value != null) {
      const dateValue = value.endOf('day');
      setEndMoment(dateValue);
      setEndDate(dateValue.format("YYYY-MM-DD"));
    } else {
      setEndMoment(null);
      setEndDate(null);
    }
  }

  async function fetchData() {
    const getLedgerUrl = `${process.env.REACT_APP_API_URL}/api/admin/bank/ledger/?bank_name=AllBank&account_type=${accountType}&start_date=${startDate || ""}&end_date=${endDate || ""}&offset=${currentOffset}`;

    setDataIsLoading(true);
    try {
      let response = await axios.get(
        getLedgerUrl,
        yourConfig
      );
      setReportData(response.data.items);

      if (response.data.items.length) {
        setNextOffset(response.data.items[response.data.items.length - 1].transaction_id);
      } else {
        setNextOffset(null);
      }

      if (currentOffset == "0") {
        setPreviousIsVisible(false);
      } else {
        setPreviousIsVisible(true);
      }
    } catch(error) {
      if (
        (error.response)
        && (error.response.status == 401)
      ) {
        Handle401()
      } else {
        setReportData([]);
        message.error(error.response.data.message);
      }
    }
    setDataIsLoading(false);
  }

  async function exportData() {
    const exportLedgerUrl = `${process.env.REACT_APP_API_URL}/api/admin/bank/ledger/?export=True&bank_name=AllBank&account_type=${accountType}&start_date=${startDate || ""}&end_date=${endDate || ""}&offset=${currentOffset}`;

    try {
      let response = await axios.get(
        exportLedgerUrl,
        yourConfig,
      );
      message.success(response.data.message);
    } catch(error) {
      if (
        error.response
        && error.response.status == 401
      ) {
        Handle401();
      } else {
        message.error(error.response.data.message);
      }
    } 
  }

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate, currentOffset]);

  useEffect(() => {
    if (reportData.length) {
      setNextIsVisible(true);
    } else {
      setNextIsVisible(false);
    }
  }, [reportData])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            columnGap: '1rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.25rem',
            }}
          >
            <div className={db.textStyle}>
              Period:
            </div>
            <div 
              style={{
                display: 'flex',
                rowGap: '0.5rem',
              }}
            >
              <DatePicker
                style={{ zIndex: 1 }}
                disabledDate={disabledStartDate}
                size="large"
                format='YYYY-MM-DD'
                value={startMoment}
                placeholder="Start"
                onChange={onStartChange}
              />
              <span style={{padding: '10px'}}>-</span> 
              <DatePicker
                style={{ zIndex: 1 }}
                disabledDate={disabledEndDate}
                size="large"
                format='YYYY-MM-DD'
                value={endMoment}
                placeholder="End"
                onChange={onEndChange}
              />
            </div>
          </div>
        </div>
        <Button
          size="large"
          type="primary"
          className="primary-btn"
          onClick={() => exportData()}
          disabled={!(startDate && endDate) || dataIsLoading}
        >
          <Icon type="download" />
          Export as CSV
        </Button>
      </div>
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem"
        }}
      >
        <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
            width: '100%',
          }}
        >
          <Table
            rowKey={record => record.transaction_id}
            rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            columns={columns}
            dataSource={reportData}
            loading={dataIsLoading}
            pagination={false}
            scroll={{ x: true }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '0.5rem',
            }}
          >
            {
              previousIsVisible &&
              <Button
                loading={dataIsLoading}
                onClick={() => {
                  setCurrentOffset("0");
                }}
              >
                { !dataIsLoading && <Icon type="double-left" /> }
                Back on Top
              </Button>
            }
            {
              nextIsVisible &&
              <Button
                loading={dataIsLoading}
                onClick={() => {
                  setCurrentOffset(nextOffset);
                }}
              >
                Next
                { !dataIsLoading && <Icon type="right" /> }
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AllBankLedger