import React, { useEffect } from 'react';
import { Select } from 'antd';
import dashboardStyles from '../../../static/css/Dashboard';
import { history } from '../../../store/history';
import UnionBankLedger from './unionbank_ledger';
import AllBankLedger from './allbank_ledger';

const Option = Select;

const BankLedger = props => {

  const db = dashboardStyles();

  const [bankName, setBankName] = React.useState("UnionBank")

  useEffect(() => {
    if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
      history.push('/login')
    } else {
      if (localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO") {
        history.push('/dashboard')
      } else if (localStorage.getItem("userType") == "PS") {
        history.push('/orders')
      } else if (localStorage.getItem("userType") == "PF") {
        history.push('/recon/7_eleven')
      } else if (localStorage.getItem("userType") == "MA") {
        history.push('/overview')
      }
    }
  }, []);

  return(
    <div className={db.main}>
      <div className={db.dashboardStyle}>
          Summary
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.25rem',
          }}
        >
          <div className={db.textStyle}>
            Bank:
          </div>
          <Select
            defaultValue="UnionBank"
            size="large"
            placeholder="Bank"
            style={{ 
              width:'10rem',
              zIndex: 1,
            }}
            onChange={(e) => {
                setBankName(e);
            }}>
              <Option value="UnionBank">UnionBank</Option>
              <Option value="AllBank">AllBank</Option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            width: '100%',
          }}
        >
          {
            bankName == "UnionBank" ? (
              <UnionBankLedger />
            ) : (
              <AllBankLedger 
                accountType="collections"
              />
            )
          }
        </div>
      </div>
    </div>
    )
}

export default BankLedger