import {isMobile} from 'react-device-detect'
import { makeStyles } from '@material-ui/core';
let kybStep = localStorage.getItem("KYBStep")
const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isSmallReso = window.screen.width * window.devicePixelRatio == 1331 && window.screen.height * window.devicePixelRatio == 669;

const amlAssessmentStyle = makeStyles(theme => ({

    outlinedBtn: {
        backgroundColor: 'transparent',
        borderRadius: '4px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
        padding: '0px 12px',
        cursor: 'pointer',
        color: '#0A315E',
        border: '1px solid #0A315E'
    },

    textColor: {
        color:' #0A315E !important',
    },

    filledBtn: {
        padding: '0px 12px',
        borderRadius: '4px',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#FFFFFF',
        cursor: 'pointer',
        backgroundColor: '#0A315E',
        border: '1px solid #0A315E',
    },

    formCardStyle: {
        width: isMobile ? '100%' : isHighReso ? '584px' : isSmallReso ? '550px' : '634px',
        backgroundColor: '#fff',
        border: '1px solid #D1D5DD',
        borderRadius: 10,
        marginBottom: isMobile && kybStep < 7 ? 80 : isMobile && kybStep > 7 ? 20 : 20,
       
    },

    uploadingBtn: {
        marginTop: 32,
        width: '100%',
        height: '40px',
        color: '#000000',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #f5f5f4',
        background: '#f5f5f4',
        cursor: 'pointer',
        outline: 0,
    },

    fileName: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#54575F',
        width: '65%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '30px'
    },

    uploadDiv:{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #E6EAF0',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: 16
    },

    stickyBtn: {
        marginLeft: '-16px',
        padding: '8px 16px',
        boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
        position: "fixed",
        zIndex: "100",
        bottom: "0",
        width: '100%',
        background: '#fff'
    },

    headerStyle: {
        fontSize: isMobile || isHighReso ? '16px' : '24px',
        fontWeight: 'bold',
        color: '#2b2d32', 

        [theme.breakpoints.down("sm")]: {
            fontSize: 16
        }
    },

    normalText: {
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
    },

    uploadText: {
        color: '#909196',
        fontSize: 16,
        lineHeight: '24px',
    },

    hoverState: {
        '&:hover': {
            background: '#E6EAF0',
        }
    },    

    miniText: {
        fontSize: '12px',
        lineHeight: '12px',
        // color: '#212B36', 
        marginBottom: '5px'
    },

    greatText: {
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 'bold',
    },

    valueText: {
        width: isMobile ? '150px' : '50%',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        fontWeight: '500',
    },

    tableStyle: {
        borderRadius: '4px',
        marginTop: 8,
    },
}))

export default amlAssessmentStyle;