import React from 'react'
import * as Style from './kyc_style'

const RejectedCard = props => {
    
    return(
        <div style={Style.rejectedCard} hidden={props.details.step != 9}>
            <div style={{...Style.boldText, color: '#E24C4C', marginBottom: '8px'}}>Application was declined</div>
            <div style={{...Style.normalText, color: '#000', marginBottom: '16px'}}>Your application has been declined. Please contact us at <b>support@bux.ph</b> if you have any questions or concerns.</div>
        </div>
    )
}

export default RejectedCard