import { useState } from "react";
import { axios } from "../../../../lib/axios";

export default function PayoutFeesViewModel() {
  const [success, setSuccess] = useState(false);
  const [providersData, setProvidersData] = useState([]);
  const [isLoadingProvider, setIsLoadingProvider] = useState(false);

  async function fetchProviders(id) {
    setIsLoadingProvider(true);

    const endpoint = id
      ? `api/admin/user_payout_fees/${id}/providers/`
      : `api/admin/payout_fees/providers/`;

    try {
      const response = await axios.get(endpoint);
      if (response.data) {
        setProvidersData(response.data);
        setIsLoadingProvider(false);
      }

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setIsLoadingProvider(false);
    }
  }

  return {
    fetchProviders,
    providersData,
    success,
    isLoadingProvider,
  };
}
