
import * as Icons from '../../../static/icons/sidebar/index';

export const admin_side_tabs = [
    {
      'menu': 'Overview',
      'active_icon': Icons.Dashboard,
      'inactive_icon': Icons.InDashboard
      
    },
    {
      'menu': 'Payment Request',
      'active_icon': Icons.PaymentReq,
      'inactive_icon': Icons.InPaymentReq
    },
    {
      'menu': 'Users',
      'active_icon': Icons.User,
      'inactive_icon': Icons.InUser,
      'sub_menu': [
          {
            'menu': 'All Users',
            'endpoint': '/users'
          },
          {
            'menu': 'BUx / Test Account',
            'endpoint': '/bux_test'
          },
          {
            'menu': 'Multiwallet',
            'endpoint': '/users/multiwallets'
          },
          {
            'menu': "Merchant IDs",
            'endpoint': '/merchant_ids'
          },
          {
            'menu': "Sub MIDs",
            'endpoint': '/gcash_submids'
          },
          {
            'menu': "Grants",
            'endpoint': '/grants'
          },
          {
            'menu': "Segment Fees",
            'endpoint': '/segment_fees'
          },
          {
            'menu': "Merchant Categories",
            'endpoint': '/merchant_categories'
          },
          {
            'menu': "Merchant IDs v2",
            'endpoint': '/merchant_ids_v2',
          },
      ]
    },
    {
      'menu': "CashBux",
      'active_icon': Icons.BankCheck,
      'inactive_icon': Icons.InBankCheck,
      'sub_menu': [
        {
          'menu': 'Merchants',
          'endpoint': '/cashbux/admins'
        },
        {
          'menu': 'Bank Submissions',
          'endpoint': '/cashbux/submissions'
        },
        {
          'menu': 'Remittances',
          'endpoint': '/cashbux/remittances'
        },
        {
          'menu': 'Bank Transfer',
          'endpoint': '/cashbux/revenues'
        },
        {
          'menu': 'Payout',
          'endpoint': '/cashbux/payouts'
        },
        {
          'menu': 'Merchant Configs',
          'endpoint': '/cashbux/merchant_configs'
        },
        {
          'menu': 'Recon Cash Out',
          'endpoint': '/cashbux/recon_cash_out/'
        },
        {
          'menu': 'Recon POS Debit',
          'endpoint': '/cashbux/recon_pos_debit/'
        },
        {
          'menu': 'Settlement',
          'endpoint': '/cashbux/settlement/'
        },
        {
          'menu': 'Bank Account',
          'endpoint': '/cashbux/bank_ledger/'
        },
      ]
    },
    {
        'menu': 'Payouts',
        'active_icon': Icons.Cashout,
        'inactive_icon': Icons.InCashout,
        'sub_menu': [
            {
                'menu': 'All Payouts',
                'endpoint': '/payouts'
            },
            {
                'menu': 'Payout Selection',
                'endpoint': '/enterprise_payout'
            },
            {
              'menu': 'Payout Advancing',
              'endpoint': '/admin/advancing'
            },
            {
              'menu': 'Global Payout Fees',
              'endpoint': '/global_payout_fees'
            },

        ]
    },
    {
      'menu': 'Reports',
      'active_icon': Icons.Card,
      'inactive_icon': Icons.InCard
    },
    {
      'menu': 'Open Finance',
      'active_icon': Icons.Finance,
      'inactive_icon': Icons.InFinance
    },
    {
      'menu': 'Wallets',
      'active_icon': Icons.Multiwallet,
      'inactive_icon': Icons.InMultiwallet,
      'sub_menu': [
          {
            'menu': 'Wallet',
            'endpoint': '/wallets'
          },
          {
            'menu': 'Add Money',
            'endpoint': '/add_money_settlement'
          }
      ]
    },
    {
      'menu': 'Reconciliation',
      'active_icon': Icons.Card,
      'inactive_icon': Icons.InCard,
      'sub_menu': [
        {
          'menu': '7-Eleven',
          'endpoint': '/recon/7_eleven/'
        },
        {
          'menu': 'Dragonpay',
          'endpoint': '/recon/dragonpay/'
        },
        {
          'menu': 'GCash',
          'endpoint': '/recon/gcash/'
        },
        {
          'menu': 'Xendit',
          'endpoint': '/recon/xendit/'
        },
        {
          'menu': 'Pay&Go',
          'endpoint': '/recon/payngo'
        },
        {
          'menu': 'DA5',
          'endpoint': '/recon/da5/'
        },
        {
          'menu': 'Posible',
          'endpoint': '/recon/posible/'
        },
        // {
        //   'menu': 'ECPay',
        //   'endpoint': '/recon/ecpay/'
        // },
        {
          'menu': 'Sentro',
          'endpoint': '/recon/sentro/'
        },
        {
          'menu': 'Reverse',
          'endpoint': '/recon/reverse/'
        },
        {
          'menu': 'Payout',
          'endpoint': '/recon/payout/'
        },
        {
          'menu': 'Generic',
          'endpoint': '/recon/generic/'
        },
        {
          'menu': 'Bayad Center',
          'endpoint': '/recon/bayad_center/'
        },
        {
          'menu': 'UnionBank',
          'endpoint': '/recon/unionbank/'
        },
      ]
    },
    {
      'menu': 'Submissions',
      'active_icon': Icons.Submission,
      'inactive_icon': Icons.InSubmission,
      'sub_menu': [
        {
          'menu': 'KYC Level 1',
          'endpoint': '/submissions/'
        },
        {
          'menu': 'KYC Level 2',
          'endpoint': '/submissions_kyc2/'
        },
        {
          'menu': 'KYC Level 3',
          'endpoint': '/submissions_kyc3/'
        },
        {
          'menu': 'BUxSME & BIZ',
          'endpoint': '/submissions_kyb/'
        },
        {
          'menu': 'Old KYB',
          'endpoint': '/old_submissions_kyb/'
        },
      ]
    },
    {
      'menu': 'Endorsement',
      'active_icon': Icons.Endorsement,
      'inactive_icon': Icons.InEndorsement,
      'sub_menu': [
        {
          'menu': 'Status',
          'endpoint': '/status_endorsement/'
        },
        {
          'menu': 'User',
          'endpoint': '/user_endorsement/'
        }
      ]
    },
    {
      'menu': 'Ledger',
      'active_icon': Icons.Ledger,
      'inactive_icon': Icons.InLedger,
      'sub_menu': [
        {
          'menu': 'Bank',
          'endpoint': '/ledger/'
        },
        {
          'menu': 'BUx',
          'endpoint': '/bux_ledger/'
        },
        {
          'menu': 'Bank Account',
          'endpoint': '/bank/ledger/'
        }
      ]
    },
    {
      'menu': 'Multi-wallet Billing',
      'active_icon': Icons.Card,
      'inactive_icon': Icons.InCard,
    },
    {
      'menu': 'Fees',
      'active_icon': Icons.Fees,
      'inactive_icon': Icons.InFees
    },
    {
      'menu': 'Partners',
      'active_icon': Icons.Partner,
      'inactive_icon': Icons.InPartner
    },
    {
      'menu': 'Marketing',
      'active_icon': Icons.Promotion,
      'inactive_icon': Icons.InPromotion,
      'sub_menu': [
        {
          'menu': `User's Feedback`,
          'endpoint': '/user_feedback/'
        },
        {
          'menu': 'Promotion',
          'endpoint': '/promotion/'
        },
      ]
    },
    {
      'menu': 'Fraud Management',
      'active_icon': Icons.Fraud,
      'inactive_icon': Icons.InFraud
    },
    {
      'menu': 'System Notification',
      'active_icon': Icons.EmailAdvisory,
      'inactive_icon': Icons.InEmailAdvisory
    },
    {
      'menu': 'Response Log',
      'active_icon': Icons.Logs,
      'inactive_icon': Icons.InLogs
    },
    {
      'menu': 'Settings',
      'active_icon': Icons.Settings,
      'inactive_icon': Icons.InSettings,
      'sub_menu': [
        {
          'menu': 'Admin',
          'endpoint': '/settings'
        },
        {
          'menu': 'Holidays',
          'endpoint': '/holidays'
        },
        {
          'menu': 'Content',
          'endpoint': '/content_settings'
        },
      ]
    },
];

export const partner_support_tabs = [
  {
    'menu': 'Submissions',
    'active_icon': Icons.Submission,
    'inactive_icon': Icons.InSubmission,
    'sub_menu': [
      {
        'menu': 'KYC Level 1',
        'endpoint': '/submissions/'
      },
      {
        'menu': 'KYC Level 2',
        'endpoint': '/submissions_kyc2/'
      },
      {
        'menu': 'KYC Level 3',
        'endpoint': '/submissions_kyc3/'
      },
      {
        'menu': 'BUxSME & BIZ',
        'endpoint': '/submissions_kyb/'
      },
      {
        'menu': 'Old KYB',
        'endpoint': '/old_submissions_kyb/'
      },
    ]
  },
]

export const kyc_support_tabs = [
  {
    'menu': 'Users',
    'active_icon': Icons.User,
    'inactive_icon': Icons.InUser,
    'sub_menu': [
        {
          'menu': 'All Users',
          'endpoint': '/users'
        },
        {
          'menu': 'BUx / Test Account',
          'endpoint': '/bux_test'
        },
    ]
  },
  {
    'menu': 'Fraud Management',
    'active_icon': Icons.Fraud,
    'inactive_icon': Icons.InFraud
  },
  {
    'menu': 'System Notification',
    'active_icon': Icons.EmailAdvisory,
    'inactive_icon': Icons.InEmailAdvisory
  },
  {
    'menu': 'Submissions',
    'active_icon': Icons.Submission,
    'inactive_icon': Icons.InSubmission,
    'sub_menu': [
      {
        'menu': 'KYC Level 1',
        'endpoint': '/submissions/'
      },
      {
        'menu': 'KYC Level 2',
        'endpoint': '/submissions_kyc2/'
      },
      {
        'menu': 'KYC Level 3',
        'endpoint': '/submissions_kyc3/'
      },
      {
        'menu': 'Old KYB',
        'endpoint': '/old_submissions_kyb/'
      },
    ]
  },
]

export const partner_finance_tabs = [
  {
    'menu': 'Reconciliation',
    'active_icon': Icons.Card,
    'inactive_icon': Icons.InCard,
    'sub_menu': [
      {
        'menu': '7-Eleven',
        'endpoint': '/recon/7_eleven/'
      },
      {
        'menu': 'Dragonpay',
        'endpoint': '/recon/dragonpay/'
      },
      {
        'menu': 'GCash',
        'endpoint': '/recon/gcash/'
      },
      {
        'menu': 'Xendit',
        'endpoint': '/recon/xendit/'
      },
      {
        'menu': 'Pay&Go',
        'endpoint': '/recon/payngo'
      },
      {
        'menu': 'DA5',
        'endpoint': '/recon/da5/'
      },
      {
        'menu': 'Posible',
        'endpoint': '/recon/posible/'
      },
      {
        'menu': 'ECPay',
        'endpoint': '/recon/ecpay/'
      },
      {
        'menu': 'Sentro',
        'endpoint': '/recon/sentro/'
      },
      {
        'menu': 'Reverse',
        'endpoint': '/recon/reverse/'
      },
      {
        'menu': 'Payout',
        'endpoint': '/recon/payout/'
      },
      {
        'menu': 'Generic',
        'endpoint': '/recon/generic/'
      },
      {
        'menu': 'Transfer money',
        'endpoint': '/recon/transfer_money/'
      },
      {
        'menu': 'Bayad Center',
        'endpoint': '/recon/bayad_center/'
      },
      {
        'menu': 'UnionBank',
        'endpoint': '/recon/unionbank/'
      },
    ]
  },
]

export const marketing_tabs = [
  {
    'menu': 'Overview',
    'active_icon': Icons.Dashboard,
    'inactive_icon': Icons.InDashboard
    
  },
  {
    'menu': 'Users',
    'active_icon': Icons.User,
    'inactive_icon': Icons.InUser,
    'sub_menu': [
        {
          'menu': 'All Users',
          'endpoint': '/users'
        },
        {
          'menu': 'BUx / Test Account',
          'endpoint': '/bux_test'
        },
    ]
  },
  {
    'menu': 'Marketing',
    'active_icon': Icons.Promotion,
    'inactive_icon': Icons.InPromotion,
    'sub_menu': [
      {
        'menu': `User's Feedback`,
        'endpoint': '/user_feedback/'
      },
      {
        'menu': 'Promotion',
        'endpoint': '/promotion/'
      },
    ]
  },
]

export const sales_admin_tabs = [
  {
    'menu': 'Overview',
    'active_icon': Icons.Dashboard,
    'inactive_icon': Icons.InDashboard
    
  },
  {
    'menu': 'Payment Request',
    'active_icon': Icons.PaymentReq,
    'inactive_icon': Icons.InPaymentReq
  },
  {
    'menu': 'Users',
    'active_icon': Icons.User,
    'inactive_icon': Icons.InUser,
    'sub_menu': [
        {
          'menu': 'All Users',
          'endpoint': '/users'
        },
        {
          'menu': 'BUx / Test Account',
          'endpoint': '/bux_test'
        },
    ]
  },
  {
      'menu': 'Payouts',
      'active_icon': Icons.Cashout,
      'inactive_icon': Icons.InCashout,
      'sub_menu': [
          {
              'menu': 'All Payouts',
              'endpoint': '/payouts'
          },
      ]
  },
  {
    'menu': 'Submissions',
    'active_icon': Icons.Submission,
    'inactive_icon': Icons.InSubmission,
    'sub_menu': [
      {
        'menu': 'BUxSME & BIZ',
        'endpoint': '/submissions_kyb/'
      },
      {
        'menu': 'Old KYB',
        'endpoint': '/old_submissions_kyb/'
      },
    ]
  },
  {
    'menu': 'Reconciliation',
    'active_icon': Icons.Card,
    'inactive_icon': Icons.InCard,
    'sub_menu': [
      {
        'menu': '7-Eleven',
        'endpoint': '/recon/7_eleven/'
      },
      {
        'menu': 'Dragonpay',
        'endpoint': '/recon/dragonpay/'
      },
      {
        'menu': 'GCash',
        'endpoint': '/recon/gcash/'
      },
      {
        'menu': 'Xendit',
        'endpoint': '/recon/xendit/'
      },
      {
        'menu': 'Pay&Go',
        'endpoint': '/recon/payngo'
      },
      {
        'menu': 'DA5',
        'endpoint': '/recon/da5/'
      },
      {
        'menu': 'Posible',
        'endpoint': '/recon/posible/'
      },
      {
        'menu': 'ECPay',
        'endpoint': '/recon/ecpay/'
      },
      {
        'menu': 'Sentro',
        'endpoint': '/recon/sentro/'
      },
      {
        'menu': 'Reverse',
        'endpoint': '/recon/reverse/'
      },
      {
        'menu': 'Payout',
        'endpoint': '/recon/payout/'
      },
      {
        'menu': 'Generic',
        'endpoint': '/recon/generic/'
      },
      {
        'menu': 'Transfer money',
        'endpoint': '/recon/transfer_money/'
      },
      {
        'menu': 'Bayad Center',
        'endpoint': '/recon/bayad_center/'
      },
      {
        'menu': 'UnionBank',
        'endpoint': '/recon/unionbank/'
      }, 
    ]
  },
  {
    'menu': 'Fees',
    'active_icon': Icons.Fees,
    'inactive_icon': Icons.InFees
  },
  {
    'menu': 'Fraud Management',
    'active_icon': Icons.Fraud,
    'inactive_icon': Icons.InFraud
  },
  {
    'menu': 'System Notification',
    'active_icon': Icons.EmailAdvisory,
    'inactive_icon': Icons.InEmailAdvisory
  },
  {
    'menu': 'Marketing',
    'active_icon': Icons.Promotion,
    'inactive_icon': Icons.InPromotion,
    'sub_menu': [
      {
        'menu': `User's Feedback`,
        'endpoint': '/user_feedback/'
      },
      {
        'menu': 'Promotion',
        'endpoint': '/promotion/'
      },
    ]
  },
]

