import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { Table, Select, DatePicker, Button, Icon, message } from 'antd';
import { Handle401 } from '../../handle401/handle401'
import dashboardStyles from '../../../static/css/Dashboard';

const Option = Select;

const UnionBankLedger = props => {
  const [reportData, setReportData] = useState([]);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  
  const [startMoment, setStartMoment] = useState(moment().endOf('day'))
  const [startDate, setStartDate] = useState(moment().endOf('day').format("YYYY-MM-DD"))
  const [endMoment, setEndMoment] = useState(moment().endOf('day'))
  const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD"))

  const [tranType, setTranType] = useState("C")

  const db = dashboardStyles();

  const yourConfig = {
    headers: {
        Authorization: "Token " + localStorage.getItem("jwtToken")
    }
  }

  const columns = [
    {
      title: 'TranID',
      dataIndex: 'tranId',
    },
    {
      title: 'Type',
      dataIndex: 'tranType',
    },
    {
      title: 'Date',
      dataIndex: 'tranDate',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Posting Date',
      dataIndex: 'postedDate',
    },
    {
      title: 'Description',
      dataIndex: 'tranDescription',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'Remarks 2',
      dataIndex: 'remarks2',
    },
    {
      title: 'Running Balance',
      dataIndex: 'balance',
    },
  ];

  const disabledStartDate = date => {
    const dateValue = date.endOf('day');
    const isFutureDate = dateValue > moment().endOf('day');

    if (endMoment) {
      const dateGreaterThanEndDate = dateValue > endMoment;
      return isFutureDate || dateGreaterThanEndDate;
    }
    return isFutureDate;
  };

  const disabledEndDate = date => {
    const dateValue = date.endOf('day');
    const isFutureDate = dateValue > moment().endOf('day');

    if (startMoment) {
      const dateLessThanStartDate = dateValue < startMoment;
      return isFutureDate || dateLessThanStartDate;
    }
    return isFutureDate;
  };
  
  const onStartChange = value => {
    if (value != null) {
      const dateValue = value.endOf('day');
      setStartMoment(dateValue)
      setStartDate(dateValue.format("YYYY-MM-DD"));
    } else {
      setStartMoment(null);
      setStartDate(null);
    }
  }

  const onEndChange = value => {
    if (value != null) {
      const dateValue = value.endOf('day');
      setEndMoment(dateValue);
      setEndDate(dateValue.format("YYYY-MM-DD"));
    } else {
      setEndMoment(null);
      setEndDate(null);
    }
  }

  async function fetchData() {
    const getLedgerUrl = `${process.env.REACT_APP_API_URL}/api/admin/bank/ledger/?start_date=${startDate || ""}&end_date=${endDate || ""}&type=${tranType}`;

    setDataIsLoading(true);
    try {
      let response = await axios.get(
        getLedgerUrl,
        yourConfig
      );
      setReportData(response.data.items);
    } catch(error) {
      if (
        (error.response)
        && (error.response.status == 401)
      ) {
        Handle401()
      } else {
        setReportData([]);
        message.error(error.response.data.message);
      }
    }
    setDataIsLoading(false);
  }

  async function exportData() {
    const exportLedgerUrl = `${process.env.REACT_APP_API_URL}/api/admin/bank/ledger/?export=True&start_date=${startDate || ""}&end_date=${endDate || ""}&type=${tranType}`;

    try {
      let response = await axios.get(
        exportLedgerUrl,
        yourConfig,
      );
      message.success(response.data.message);
    } catch(error) {
      if (
        error.response
        && error.response.status == 401
      ) {
        Handle401();
      } else {
        message.error(error.response.data.message);
      }
    } 
  }

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate, tranType]);

  return(
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            columnGap: '1.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.25rem',
            }}
          >
            <div className={db.textStyle}>
              Period:
            </div>
            <div 
              style={{
                display: 'flex',
                rowGap: '0.5rem',
              }}
            >
              <DatePicker
                style={{ zIndex: 1 }}
                disabledDate={disabledStartDate}
                size="large"
                format='YYYY-MM-DD'
                value={startMoment}
                placeholder="Start"
                onChange={onStartChange}
              />
              <span style={{padding: '10px'}}>-</span> 
              <DatePicker
                style={{ zIndex: 1 }}
                disabledDate={disabledEndDate}
                size="large"
                format='YYYY-MM-DD'
                value={endMoment}
                placeholder="End"
                onChange={onEndChange}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.25rem',
            }}
          >
            <div className={db.textStyle}>
              Transaction Type:
            </div>
            <Select
              defaultValue="C"
              size="large"
              placeholder="Type"
              style={{
                width:'120px', 
                zIndex: 1,
              }}
              onChange={(value) => {
                setTranType(value);
              }}>
                <Option value="C">Credit</Option>
                <Option value="D">Debit</Option>
            </Select>
          </div>
        </div>
        <Button
          size="large"
          type="primary"
          className="primary-btn"
          onClick={() => exportData()}
          disabled={!(startDate && endDate) || dataIsLoading}
        >
          <Icon type="download" />
          Export as CSV
        </Button>
      </div>
      
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem"
        }}
      >
        <Table
          rowKey={record => record.tranId}
          rowClassName={(_, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          columns={columns}
          dataSource={reportData}
          loading={dataIsLoading}
          pagination={false} 
        />
      </div>
    </>
  )
}

export default UnionBankLedger