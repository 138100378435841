import React, { useState, useEffect } from "react";
import MetaTag from "../../../meta_tag/meta_tag";
import { Typography, Button, Icon, Switch, Card } from "antd";
import { history } from "../../../../store/history";
import MerchantPayoutFeesViewModel from "./merchant_payout_fees_viewmodel";
import PayoutFeesTable from "../components/payout_fees_table";
import PayoutFeeModal from "../components/payout_fee_modal";
import "../css/payout_fees.css";

const MerchantPayoutFees = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const {
    fetchMerchantPayoutFeeList,
    addMerchantPayoutFee,
    editMerchantPayoutFee,
    toggleMerchantPayoutFee,
    enablePayoutFee,
    getUserPayoutFeesSettings,
    merchantPayoutFeesData,
    success,
    messageDisplay,
    isLoading,
    setShoulderEnabled,
    shoulderEnabled,
  } = MerchantPayoutFeesViewModel();

  function closeModal() {
    setOpenModal(false);
    // fetchGlobalPayoutFeeList(tempSearchQuery, currentPage);
  }

  function onSubmitModal() {
    setOpenModal(false);
    // setCurrentPage(1);
    // fetchGlobalPayoutFeeList(tempSearchQuery, 1);
  }

  function openEditModal(record) {
    setEditData(record);
    setOpenModal(true);
  }

  useEffect(() => {
    if (
      localStorage.getItem("jwtToken") === "" ||
      localStorage.getItem("jwtToken") == null
    ) {
      history.push("/login");
    } else {
      if (
        localStorage.getItem("userType") == "ME" ||
        localStorage.getItem("userType") == "CO"
      ) {
        history.push("/dashboard");
      } else if (localStorage.getItem("userType") == "PS") {
        history.push("/orders");
      } else if (localStorage.getItem("userType") == "PF") {
        history.push("/recon/7_eleven");
      } else if (localStorage.getItem("userType") == "MA") {
        history.push("/overview");
      } else {
        getUserPayoutFeesSettings(props.id);
      }
    }
  }, []);

  return (
    <div
      style={{
        margin: "0 2.5em 2.5em 2.5em",
        display: "flex",
        gap: "2em",
        flexDirection: "column",
        paddingBottom: "50px",
        width: "71vw",
      }}
    >
      <Card size="small" className="payout-card">
        <div className="main">
          <div className="content">
            <span className="title-label">
              <strong>
                Payout Fee is {shoulderEnabled ? "enabled" : "disabled"}
              </strong>
              <div>
                {shoulderEnabled ? (
                  <label
                    style={{
                      color: "#909196",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    Payouts are subject to a processing fee.
                  </label>
                ) : (
                  <label
                    style={{
                      color: "#909196",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    Payouts are processed without service charges.
                  </label>
                )}
              </div>
            </span>
          </div>
          <Switch
            checked={shoulderEnabled}
            onChange={async () => {
              await enablePayoutFee(props.id);
              setShoulderEnabled(!shoulderEnabled);
            }}
            style={{
              margin: "12px 20px 12px 12px",
              backgroundColor: `${shoulderEnabled ? "#0a315e" : "#D1D5DD"}`,
            }}
            disabled={isLoading}
          />
        </div>
      </Card>
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            type="primary"
            className="add-payout-fee-button"
            onClick={() => {
              setEditData(null);
              setOpenModal(true);
            }}
          >
            <Icon type="plus" />
            Add Merchant Payout Fee
          </Button>
        </div>
        <PayoutFeesTable
          fetchPayoutFeeList={fetchMerchantPayoutFeeList}
          togglePayoutFee={toggleMerchantPayoutFee}
          payoutFeesData={merchantPayoutFeesData}
          isLoading={isLoading}
          success={success}
          messageDisplay={messageDisplay}
          openEditModal={openEditModal}
          isModalOpen={openModal}
          id={props.id}
        />
      </div>

      <PayoutFeeModal
        editData={editData}
        openModal={openModal}
        closeModal={closeModal}
        submitModal={onSubmitModal}
        addPayoutFee={addMerchantPayoutFee}
        editPayoutFee={editMerchantPayoutFee}
        success={success}
        messageDisplay={messageDisplay}
        type={"Merchant"}
        id={props.id}
      />
    </div>
  );
};

export default MerchantPayoutFees;
