import React from 'react'
import axios from 'axios'
import {Card, Icon, Divider} from 'antd'
import amlAssessmentStyle from './amlAssessmentStyle'
import { isMobile } from 'react-device-detect'
import AML from '../../static/files/BUX-AML-Questionnaire.pdf'
import UploadProgressBar from '../kyb/forms/custom_component/upload_progress_bar'
import { useHistory } from "react-router-dom"


const AMLAssessment = props => {
    const history = useHistory();
    const data = props.datas
    const currentStep = data.step;
    const amlStyle = amlAssessmentStyle()

    //alert message
    const stripQueryParams = (myString) => myString.slice(65).replace(/\?.*/,'')

    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(false)

    const [aml, setAml] = React.useState("")
    const [amlFile, setAmlFile] = React.useState("")
    const [amlFilename, setAmlFilename] = React.useState(!data || data.aml_questionnaire_attachment
        == null || data.aml_questionnaire_attachment
        == "" ? null : stripQueryParams(data.aml_questionnaire_attachment
        ))
    const [isTouched, setIsTouched] = React.useState(false)
    const [hideSpin, setHideSpin] = React.useState(true)
    
    var filesToUploadCount = 0;
    var filesUploadedCount = 0;
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'SD']
    const segments = localStorage.getItem("segments")

    let amlUpload = React.useRef()
    
    let userId = ['CO','SW'].includes(localStorage.getItem('userType')) ? 
                    localStorage.getItem('userId') : 
                localStorage.getItem("KYBType") == "creation" ? 
                    data.id : props.userId

    const legal_docu = {   
        title: isMobile ? 'AML' : 'AML Assessment',
        text: isMobile ? 'AML' : 'Download AML Questionnaire',
        name: 'Upload Download AML Questionnaire',
        error: 'AML',
        ref: amlUpload,
        file_name: amlFilename,
        value: aml,
        file: AML
    }


    React.useEffect(() => {

    },[])

    //handle upload file
    const handleUploadFile = (info, type) =>{
        setIsTouched(true)
        setError(false)
        //Corporate documents
        setAmlFile(info)
        setAmlFilename(info.name)
    }  

    //removing file
    function handleRemoveFile(type){
        //Corporate documents
        setIsTouched(true)
        setAmlFilename(null)
        setAmlFile("")
    }

    function validateFields(){
        let i;
        let mb = 10000000;
        setHideSpin(false)
        let acceptedFiles = ['application/pdf']

        if(legal_docu.file_name == null){
            setError(true)
            setHideSpin(true)
            setErrorMessage(`Please download and review our ${legal_docu.error} and upload it with answers`)
            window.scrollTo(0, 0)
            return
        }
        else if((legal_docu.file_name != null && legal_docu.ref.current != null) && (legal_docu.ref.current.files.length > 0)){
            if(!acceptedFiles.includes(legal_docu.ref.current.files[0].type)) {
                setError(true)
                setHideSpin(true)
                setErrorMessage(`${legal_docu.name} file must be .pdf only`)
                window.scrollTo(0, 0)
                return

            }
            else if(legal_docu.ref.current.files[0].size > mb){
                setError(true)
                setHideSpin(true)
                setErrorMessage(`${legal_docu.name} file size must not exceed 10MB`)
                window.scrollTo(0, 0)
                return
            }
        }
        else {
            console.log(legal_docu)
        }

        submitForm()
    }

    const yourConfig = {
        headers: {
            Authorization: "Token " + localStorage.getItem("jwtToken")
        },
        onUploadProgress: recalculateUploadProgress
    }

    function recalculateUploadProgress(progressEvent) {
        /* To manually invoke the multi-packet upload behavior, use the browser's Network throttling tool.
           If your internet is fast enough, axios only fires this function once, so throttling is necessary. */

        const recomputedProgress = filesUploadedCount + (progressEvent.loaded / progressEvent.total);
        setUploadProgress(100 * recomputedProgress / filesToUploadCount);
    }

    async function submitDocument(params){
        try{
            if (data.user_type == "ME" || data.user_type == "SW") {
                await axios.post(process.env.REACT_APP_API_URL + "/api/kyc/",params,yourConfig)
            } else {
                await axios.patch(process.env.REACT_APP_API_URL + "/api/kyb/",params,yourConfig)
            }
        }
        catch(error){
            console.log(error.message)
        }
    }

    async function submitForm(){
        filesUploadedCount = 0;
        setUploadProgress(0);
        var formData = new FormData();

        let attachmentsToUpload = {
            'aml_questionnaire_attachment': amlFile,
        };

        if (data.user_type == "ME" || data.user_type == "SW") {
            formData.append('step', currentStep)
        } else {
            formData.append('submission_id', userId)
            formData.append('step_to_update', currentStep)
        }

        filesToUploadCount = Object.values(attachmentsToUpload)
                                   .filter(attached_file => attached_file !== '')
                                   .length;

        if (filesToUploadCount) {
            for (const [attachmentName, attachedFile] of Object.entries(attachmentsToUpload)) {
                if (attachedFile === '') {
                    continue;
                }

                formData.append(attachmentName, attachedFile);
                await submitDocument(formData);

                filesUploadedCount++;
                formData.delete(attachmentName);
            }
        }

        setHideSpin(true);

        if (props.submitStep) {
            props.submitStep();
        }
    }

    function editBusinessInfo(val){
        if (props.openEdit) {
            props.openEdit(val)
        } else {
            history.goBack();
        }
    }

    return(
        <>
            <Card className={amlStyle.formCardStyle} bodyStyle={{padding: '20px 16px 20px 16px'}}>
                <div className={`twoCol`}>
                    <div className={amlStyle.headerStyle}style={{ fontSize: isMobile ? '16px' : '20px'}}>AML Assessment</div>
                    <div>
                        <a href className={`text-${segments} medium-font-weight`} onClick={() => editBusinessInfo(7)} hidden={['CO','SW'].includes(localStorage.getItem('userType')) && currentStep < 7 || localStorage.getItem('KYBType') == 'creation' && data ? data.step < 7 : ''}>Cancel</a>
                    </div>
                </div>

                <Divider />

                {props.alertMessage}

                <div
                    style={{
                        backgroundColor: 'rgba(226, 76, 76, 0.1)',
                        borderLeft: '4px solid #E24C4C',
                        width: '100%',
                        borderRadius: '4px',
                        marginBottom: '20px',
                        marginTop: '20px',
                        textAlign: 'left',
                        padding: '10px',
                        display: error ? "block" : "none"
                    }}>
                    <span className={amlStyle.normalText}>{errorMessage}</span>
                </div>

                <div>
                    <div className="twoCol top-20 liveColor">
                        <div>
                            <div className="smallBoldStyle">
                                Step 1   
                            </div>
                            <div className="normalTextStyle top-4">
                            Download the file.
                            </div>
                        </div>
                        <div>
                            <a href={legal_docu.file} download className={`text-${segments}`}>
                                <button className={`outline-btn--${segments} btn-height right-8`}>
                                    <span> <Icon type="download" className={`right-4`}/> {legal_docu.text}</span>
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="top-32 bottom-20 liveColor">
                        <div>
                            <div className="smallBoldStyle">
                                Step 2
                            </div>
                            <div className="normalTextStyle top-4">
                            Answer the questionnaire.
                            </div>
                        </div>
                    </div>

                    <div className="top-32 bottom-20 liveColor">
                        <div>
                            <div className="smallBoldStyle">
                                Step 3 
                            </div>
                            <div className="normalTextStyle top-4">
                            Upload the downloaded file.
                            </div>
                        </div>
                    </div>

                    <input type="file"
                        data-test="upload-file-input"
                        ref={legal_docu.ref}
                        style={{display: 'none'}}
                        onChange={(e)=>handleUploadFile(e.target.files[0], legal_docu.title)}
                        accept=".pdf"
                        >
                    </input>

                    <div className={amlStyle.uploadDiv}>
                        <div className={amlStyle.uploadText} style={{padding: '7px 0px 7px 0px'}}>
                        {/* upload */}
                            <div style={{display: legal_docu.file_name == null ? 'block' : 'none'}}>Upload File</div> 
                            {/* preview */}
                            <div style={{display: legal_docu.file_name != null ? 'block' : 'none'}}>
                                <div style={{display: 'flex', width: isMobile ? 250 : ''}}>
                                    <Icon type="file" className={`text-${segments} top-4 right-8`}/>
                                    <div data-test={legal_docu.file_name} id="fileName" className={amlStyle.fileName} style={{width: isMobile ? '150px' : ''}}>{legal_docu.file_name}</div>
                                </div>  
                            </div>
                        </div>

                        <div>
                            {/* upload */}
                            <div style={{display: legal_docu.file_name == null ? 'block' : 'none'}}>
                                <button data-test="upload-btn" className={`outline-btn--${segments} btn-small-height`} onClick={()=>legal_docu.ref.current.click()}>
                                    <Icon type="upload" className="right-4" /> Upload
                                </button>
                            </div>

                            {/* preview */}
                            <div style={{display: legal_docu.file_name != null ? 'block' : 'none', position: 'absolute', right: 42, marginTop: 8}}>
                                <a href onClick={()=>{legal_docu.ref.current.value = null; handleRemoveFile(legal_docu.title)}} style={{padding: '8px 0px 0px 0px', fontWeight: 600}} className={`text-${segments}`}><Icon type='close' style={{fontSize: 20, fontWeight: 'bold'}} /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div hidden={isMobile}>
                    <button className={`btn--${segments} btn-height top-20 right-8`}
                        hidden={!hideSpin}
                        style={{
                            width: '100%',
                            opacity: (props.editMode && !isTouched) ? 0.5 : '', 
                            cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                        disabled={(props.editMode && !isTouched)} 
                        onClick={() => validateFields()}
                        > 
                        Save Changes
                    </button>

                    <button className={amlStyle.uploadingBtn} hidden={hideSpin}>
                        <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                            <UploadProgressBar value={uploadProgress}/>
                        </div>
                    </button>

                    {props.footerNote}

                </div>
            </Card>
            <div className={amlStyle.stickyBtn} hidden={!isMobile}>
                <button className={`btn--${props.segments} btn-mobile-width`} 
                    hidden={!hideSpin}
                    style={{
                        opacity: (props.editMode && !isTouched) ? 0.5 : '', 
                        cursor: (props.editMode && !isTouched) ? 'not-allowed' : 'pointer'}}
                    disabled={(props.editMode && !isTouched)} 
                    onClick={() => validateFields()}
                    > 
                    Save Changes
                </button>

                <button className={amlStyle.uploadingBtn} hidden={hideSpin}>
                    <div style={{paddingLeft: '10px', paddingRight: '10px'}}>
                        <UploadProgressBar value={uploadProgress}/>
                    </div>
                </button>
            </div>
        </>
    )
}

export default AMLAssessment
