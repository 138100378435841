import React, {useEffect} from 'react'
import {Select, Input, message, Divider, Icon} from 'antd'
import axios from 'axios'
import { Handle401 } from '../handle401/handle401'
import { history } from '../../store/history'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import QRCode from 'react-qr-code'
import { exportComponentAsPNG } from 'react-component-export-image'
import * as Style from "./style"

//Icons

import EmailIcon from '../../static/icons/new_email_share.svg'
import MessengerIcon from '../../static/icons/new_messenger_share.svg'
import CopyIcon from '../../static/icons/copy_link.svg'
import { Download } from '../../static/icons/bux_qr'

const PaymentSchedDiv = props => {

    const { 
        amount,
        description,
        fullName,
        email,
        contact,
        resetCodeDetails,
        response,
        schedHidden,
        setSchedHidden
    } = props

    const { Option } = Select
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const FB_APP_ID = process.env.REACT_APP_FB_ID
    const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL

    const segments = localStorage.getItem("segments")

    let plink

    const [scheduleType, setScheduleType] = React.useState("WE")
    const [totalNumberReq, setTotalNumberReq] = React.useState(1)
    const [specificDay, setSpecificDay] = React.useState("")
    const [nextDate, setNextDate] = React.useState("")
    const [nextDateToBePassed, setNextDateToBePassed] = React.useState("")

    const [showAlert, setShowAlert] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState("")

    const testMode = history.location.pathname.indexOf('/test') > -1;

    // QR code
    const paymentQrRef = React.useRef();
    const paymentLink = response && response.uid? `${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}` : '';

    useEffect(()=>{
        setNextDateNow(scheduleType, specificDay)
    },[])

    const QrOnly = React.forwardRef(() => (
        <div align="center" style={{position: 'absolute', top: '-11000px'}}>
            <div ref={paymentQrRef} style={{padding: '4px', backgroundColor: '#FFF'}}>
                <QRCode value={paymentLink} size={460} level="L" />
            </div>
        </div>
    ))

    function leapYear(year)
    {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    function setNextDateNow(type, specDay) {
        let latestMoment = moment()

        if(type == 'DA'){
            latestMoment = latestMoment.clone().add(1, 'days')
        }
        else if(type == 'WE'){
            latestMoment = latestMoment.clone().add(7, 'days')

        }
        else if(type == 'HM'){
            latestMoment = latestMoment.clone().add(15, 'days')
        }
        else if(type == 'MO'){
            latestMoment = latestMoment.clone().add(30, 'days')
        }

        else{
            if(latestMoment){
                if(parseInt(latestMoment.format('DD')) < parseInt(specDay) && !(parseInt(latestMoment.format('MM')) == 2 && (latestMoment.format('DD') == 29 || latestMoment.format('DD') == 28))){
                    let newDate = new Date(parseInt(latestMoment.format('YYYY')), parseInt(latestMoment.format('MM')) - 1, parseInt(specDay))
                    latestMoment = moment(newDate)
                }
                else{
                    if(parseInt(latestMoment.format('MM')) == 12){
                        let newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 0, parseInt(specDay))
                        latestMoment = moment(newDate)                        
                    }
                    else if(parseInt(latestMoment.format('MM')) == 1 && (specDay == 30 || specDay == 31)){
                        let newDate;
                        if(leapYear(latestMoment.year)){
                            newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 1, 29)
                        }
                        else{
                            newDate = new Date(parseInt(latestMoment.format('YYYY')) + 1, 1, 28)
                        }
                        latestMoment = moment(newDate)
                    }
                    else if(parseInt(latestMoment.format('MM')) == 2 && (specDay == 30 || specDay == 31 || (!leapYear(latestMoment.year) && specDay == 29))){
                        let newDate = new Date(parseInt(latestMoment.format('YYYY')), 2, parseInt(specDay))
                        latestMoment = moment(newDate)
                    }
                    else{
                        let newDate = new Date(parseInt(latestMoment.format('YYYY')), parseInt(latestMoment.format('MM')), parseInt(specDay))
                        latestMoment = moment(newDate)
                    }
                }
            }
        }
        
        setNextDateToBePassed(latestMoment.format('MM-DD-YY'))
        setNextDate(latestMoment.format('MMMM DD, YYYY hh:mm A'))

    }

    async function scheduleNow(){

        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let selectedCycle = scheduleType
        
        if(selectedCycle == 'SP'){
            selectedCycle = 'MO'
        }

        let params = {
            amount: amount,
            description: description,
            buyer_phone: contact,
            buyer_email: email,
            buyer_name: fullName,
            trigger_date: moment().format('MM-DD-YY'),
            cycle: selectedCycle,
            specific_day: specificDay,
            number_of_request: totalNumberReq
        }

        if(totalNumberReq > 12 || totalNumberReq < 1){
            message.error("Minimum of 1 and Maximum of 12 total number of request")
        }
        else{
            try{
                let response = null
                if(!testMode){
                    response = await axios.post(
                        process.env.REACT_APP_API_URL + "/api/orders/recurring/",
                        params,yourConfig
                    );
                }
                else{
                    response = await axios.post(
                        process.env.REACT_APP_API_URL + "/api/sandbox/schedule_payment/",
                        params,yourConfig
                    );
                }
                
        
                if (response.data.status == "success"){
                   message.success("Successfully Added")
                   setSchedHidden(true)
                //    hideModal()
                //    fetchData()
                }
            }
            
            catch(error){
                if((error.response)&&(error.response.status == 401)){
                    Handle401()
                }
            }
        }
     
    }

    function settingCycle(val){
        setScheduleType(val)
        setNextDateNow(val, specificDay)
        if(val == 'SP'){
            setNextDate("")
        }
    }

    function settingSpecificDay(val) {
        if(val <= 31){
            setNextDate(null)  
            setSpecificDay(val)
            setNextDateNow(scheduleType, val)
        }
        if(val == ""){
            setNextDate("")
        }
    }

    function copyClipBoard() {
        plink.select()
        document.execCommand('copy')
    }

    function onCopyLink(){
        copyClipBoard()
        message.destroy()
        message.success('Copied to clipboard.', 3)
    }

    

    let emailBody = `Hi BUx Customer
      
Merchant has sent you a payment link. You may click or copy the Payment Link for you to be able to access the checkout page.

Payment Link Details:
Amount: ${response.amount}
Payment Link: ${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}
Payment link will expire on: ${response.expiry}
Payment details and merchant information can be found on the URL.

Thank you!`

    let y = encodeURIComponent(emailBody)

    let emailSubject = `Payment Request from ${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}: Payment Link will expire on ${response.expiry}`



    const fbDisplay = (data) => {
        if (isMobile) {
          return (
            <a href={`fb-messenger://share/?app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}/payment/${data.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`}>
                <img src={MessengerIcon}/>
            </a>);
        }
        else {
          return (
            <a href={`${FB_SHARE_URL}redirect_uri=${REACT_APP_BASE_URL}/dashboard&app_id=${FB_APP_ID}&link=${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}/%3Futm_source%3Dbux%26utm_medium%3Dcode-facebook`} target="_blank">
                <img src={MessengerIcon}/>
            </a>);
        }
    }
    
    
    
    return(
        <div style={{marginTop: '10px'}}>
            <QrOnly />
            <div style={style.detailsDiv}>
                <div style={{...style.boldText, fontSize: '14px', marginBottom: '20px'}}>Customer Information</div>
                <div style={{...style.normalText, fontSize: '14px', marginBottom: '4px'}}>{fullName}</div>
                <div style={{...style.normalText, fontSize: '14px', marginBottom: '16px'}}>+63{contact} | {email}</div>
                <div style={style.amountDiv}>
                    <div style={{...style.normalText, fontSize: '16px'}}>Amount</div>
                    <div style={{...style.boldText, fontSize: '16px'}}>₱ {parseFloat(amount).toFixed(2)}</div>
                </div>
                <Divider/>
               <div style={{display: 'flex', justifyContent: 'center'}}>
                <QRCode value={`${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}`} size="140" level={'L'} />               </div>
                <Divider />
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '12px'}}>
                    <div style={{display: 'flex', cursor: 'pointer'}} onClick={()=>onCopyLink()}>
                        <img src={CopyIcon} style={{marginRight: '4px'}}/>
                        <div style={{...style.semiBoldText, fontSize: '14px'}} >Copy payment link</div>
                    </div>
                    <button style={Style.btnstyle} onClick={() => { message.loading("Please Wait...", 1.5); exportComponentAsPNG(paymentQrRef);}}>
                        <img src={Download} alt="logo" />
                        <div style={Style.btnText}>Download</div>
                    </button>
                </div>
            </div>
            <Divider> <span style={{color: '#333333', fontSize: '12px', opacity: '0.8'}}>Or share this link via</span></Divider>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '34px'}}>
                <a href={`mailto:?subject=${emailSubject}&body=${y}`} title="Email">
                    <img src={EmailIcon} style={{marginRight: '32px'}}/>
                </a>
                {fbDisplay(response)}
            </div>


            <div hidden={schedHidden}>
                <div style={{...style.boldText, fontSize: '16px', marginBottom: '16px'}}>Set a payment request schedule</div>
                <div style={style.labelText}>Payment request schedule type</div>
                <Select
                    value={scheduleType}
                    size="large"
                    onChange={(e)=>settingCycle(e)}
                    suffixIcon={<Icon type="caret-down" />}
                    style={{width: '100%', zIndex: 1, color: '#000', marginBottom: '16px'}}
                    >
                    <Option value="DA">Daily</Option>
                    <Option value="WE" >Weekly</Option>
                    <Option value="HM" >Every 15 days</Option>
                    <Option value="MO">Every 30 days</Option>
                    <Option value="SP">Specific day of Month</Option>
                </Select>

                <div hidden={scheduleType != 'SP'}>
                    <div style={style.labelText} >Specific day</div>
                    <Input size="large" onChange={(e)=>settingSpecificDay(e.target.value)}style={{width: '100%', marginBottom: '16px'}} />
                </div>

                <div style={style.labelText}>Total no. request to send</div>
                <Input type="number" value={totalNumberReq} size="large" onChange={(e)=>setTotalNumberReq(e.target.value)} style={{width: '100%', marginBottom: '16px'}} />

                <div style={{...style.boldText, fontSize: '16px', marginBottom: '4px'}}>Next payment request schedule</div>
                <div style={{...style.normalText, fontSize: '16px', marginBottom: '32px'}}>{nextDate}</div>

                <button style={style.scheduleBtn} className={`btn--${segments}`} disabled={totalNumberReq == ""} onClick={()=>scheduleNow()}>Schedule payment request</button>
                <div style={{...style.boldText, fontSize: '16px', textAlign: 'center', cursor: 'pointer'}} onClick={()=>setSchedHidden(true)}>Cancel schedule request</div>

            </div>


            <div hidden={!schedHidden}>
                <button style={style.scheduleBtn} className={`btn--${segments}`} onClick={()=>resetCodeDetails()}>Done</button>
            </div>
            
            <Input type="text" readOnly value={`${REACT_APP_BASE_URL}${testMode ? '/test/checkout/' : '/checkout/'}${response.uid}`} size="large" style={{ position: 'absolute', top: '-10000px' }} ref={(textarea) => plink = textarea} />
        </div>
    )
}

const style = {
    detailsDiv: {
        width: '100%',
        border: '1px solid #E9EEF4',
        borderTop: '7px solid #62CE90',
        boxShadow: '8px 0px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '20px'
    },
    normalText:{
        fontWeight: '400',
        color: '#2b2d32'
    },
    semiBoldText:{
        fontWeight: '500',
        color: '#2b2d32'
    },
    boldText: {
        fontWeight: '700',
        color: '#2b2d32'
    },
    labelText:{
        fontSize:'12px',
        color: 'rgba(43, 45, 50, 0.8)'
    },
    amountDiv: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#E9EEF4',
        padding: '4px 16px 4px 16px'
    },
    scheduleBtn: {
        height: '40px',
        width: '100%',
        marginBottom: '20px'
    }
}
export default PaymentSchedDiv