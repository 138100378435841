import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import { TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../css/payout_fees.css";
import { validateNumberWithDecimal } from "../../../../utils/validators/validateNumberWithDecimal";
import PayoutFeesViewModel from "./payout_fee_viewmodel";

const PayoutFeeModal = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [openProvider, setOpenProvider] = useState(false);
  const [openChannel, setOpenChannel] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const [channelsList, setChannelsList] = useState([]);

  const [formFieldData, setFormFieldData] = useState({
    provider: "",
    providerCode: "",
    channel: "",
    channelCode: "",
    bankFee: "",
    bankFeePercent: "",
    merchantFee: "",
    merchantFeePercent: "",
    ubxFee: "",
    ubxFeePercent: "",
  });

  const { addPayoutFee, editPayoutFee, success, messageDisplay, type } = props;

  const { fetchProviders, providersData, isLoadingProvider } =
    PayoutFeesViewModel();

  async function submitFeeConfig() {
    if (
      formFieldData.provider != "" &&
      formFieldData.channel != "" &&
      formFieldData.channelCode != "" &&
      formFieldData.bankFee != "" &&
      formFieldData.bankFeePercent != "" &&
      formFieldData.merchantFee != "" &&
      formFieldData.merchantFeePercent != "" &&
      formFieldData.ubxFee != "" &&
      formFieldData.ubxFeePercent != ""
    ) {
      let params = {
        provider: formFieldData.providerCode,
        channel: formFieldData.channel,
        channel_code: formFieldData.channelCode,
        bank_fee: formFieldData.bankFee,
        bank_fee_percent: formFieldData.bankFeePercent,
        merchant_fee: formFieldData.merchantFee,
        merchant_fee_percent: formFieldData.merchantFeePercent,
        ubx_fee: formFieldData.ubxFee,
        ubx_fee_percent: formFieldData.ubxFeePercent,
      };
      if (props.editData) {
        await editPayoutFee(params, props.editData.id);
      } else {
        await addPayoutFee(params, props.id);
      }
    } else {
      setIsTouched(true);
    }
  }

  function clearForm() {
    setFormFieldData({
      provider: "",
      providerCode: "",
      channel: "",
      channelCode: "",
      bankFee: "",
      bankFeePercent: "",
      merchantFee: "",
      merchantFeePercent: "",
      ubxFee: "",
      ubxFeePercent: "",
    });
    setIsTouched(false);
  }

  function updateFields(fields) {
    setFormFieldData({ ...formFieldData, ...fields });
  }

  useEffect(() => {
    if (props.editData) {
      setFormFieldData({
        provider: props.editData.provider,
        channel: props.editData.channel,
        channelCode: props.editData.channel_code,
        bankFee: props.editData.bank_fee,
        bankFeePercent: props.editData.bank_fee_percent,
        merchantFee: props.editData.merchant_fee,
        merchantFeePercent: props.editData.merchant_fee_percent,
        ubxFee: props.editData.ubx_fee,
        ubxFeePercent: props.editData.ubx_fee_percent,
      });
    }
  }, [props.editData]);

  useEffect(() => {
    fetchProviderOptions();
  }, [props.openModal]);

  useEffect(() => {
    if (providersData) {
      let providerArray = [];

      providersData.map((item, key) => {
        providerArray.push({
          name: item.provider_name,
          value: item.provider_code,
        });
      });

      setProvidersList(providerArray);
    }
  }, [providersData]);

  useEffect(() => {
    if (providersData) {
      let channelsArray = [];

      const selectedProvider = providersData.filter(
        (provider) => provider.provider_name === formFieldData.provider
      );

      if (selectedProvider && selectedProvider.length > 0) {
        selectedProvider[0].channels.map((item, key) => {
          channelsArray.push({
            name: item.name,
            value: item.code,
          });
        });
      }

      setChannelsList(channelsArray);
    }
  }, [formFieldData.provider]);

  useEffect(() => {
    displayMessage();
  }, [messageDisplay, success]);

  async function fetchProviderOptions() {
    await fetchProviders(props.id);
  }

  function displayMessage() {
    setTimeout(() => {
      if (messageDisplay != "" && success == true) {
        message.destroy();
        message.success(messageDisplay);
        clearForm();

        if (props.editData) {
          props.closeModal();
        } else {
          props.submitModal();
        }
      } else if (messageDisplay != "" && success == false) {
        message.destroy();
        message.error(messageDisplay);
      }
    }, 1000);
  }

  function displayTextField(label, onChange, value, disabled) {
    return (
      <Grid item={true}>
        <TextField
          id="outlined-basic"
          label={label}
          disabled={disabled}
          margin="normal"
          variant="outlined"
          fullWidth
          className={isTouched && value == "" ? `error-field` : ``}
          onChange={onChange}
          value={value}
        />
        {isTouched && value == "" && (
          <p className="error-field-message">This is a required field</p>
        )}
      </Grid>
    );
  }

  return (
    <Modal
      title={
        props.editData ? `Edit ${type} Payout Fee` : `Add ${type} Payout Fee`
      }
      centered
      visible={props.openModal}
      onCancel={() => {
        clearForm();
        props.closeModal();
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            clearForm();
            props.closeModal();
          }}
          className="cancel-payout-fee-button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="add-payout-fee-button"
          onClick={() => submitFeeConfig()}
        >
          {props.editData ? "Update" : "Add"}
        </Button>,
      ]}
    >
      <Grid container>
        <div className="field-row-container">
          <Grid item={true} className="full-width-grid-item">
            <Autocomplete
              style={{
                width: "100%",
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openProvider}
              onOpen={() => {
                setOpenProvider(true);
              }}
              onClose={() => {
                setOpenProvider(false);
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={providersList}
              loading={isLoadingProvider}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Provider"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingProvider ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => updateFields({ provider: e.target.value })}
                  value={formFieldData.provider}
                />
              )}
              onChange={async (option, value) => {
                if (value && value.value) {
                  setFormFieldData({
                    ...formFieldData,
                    provider: value.name,
                    providerCode: value.value,
                    channel: "",
                    channelCode: "",
                  });
                } else {
                  setFormFieldData({
                    ...formFieldData,
                    provider: "",
                    providerCode: "",
                    channel: "",
                    channelCode: "",
                  });
                }
              }}
              inputValue={formFieldData.provider}
              value={formFieldData.provider}
              disabled={props.editData}
            />

            {isTouched && formFieldData.provider == "" && (
              <p className="error-field-message">This is a required field</p>
            )}
          </Grid>
        </div>

        <div className="field-row-container">
          <div style={{ width: "50%" }}>
            <Autocomplete
              style={{
                marginBottom: "8px",
                marginTop: "16px",
              }}
              open={openChannel}
              onOpen={() => {
                setOpenChannel(true);
              }}
              onClose={() => {
                setOpenChannel(false);
              }}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              options={channelsList}
              loading={isLoadingProvider}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Channel"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingProvider ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={(e) => updateFields({ channel: e.target.value })}
                  value={formFieldData.channel}
                />
              )}
              onChange={async (option, value) => {
                if (value && value.value) {
                  setFormFieldData({
                    ...formFieldData,
                    channel: value.name,
                    channelCode: value.value,
                  });
                } else {
                  setFormFieldData({
                    ...formFieldData,
                    channel: "",
                    channelCode: "",
                  });
                }
              }}
              inputValue={formFieldData.channel}
              value={formFieldData.channel}
              disabled={props.editData || !formFieldData.provider}
            />

            {isTouched && formFieldData.channel == "" && (
              <p className="error-field-message">This is a required field</p>
            )}
          </div>

          {displayTextField(
            "Channel Code",
            (e) => updateFields({ channelCode: e.target.value }),
            formFieldData.channelCode,
            true
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Bank Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ bankFee: e.target.value });
              }
            },
            formFieldData.bankFee,
            false
          )}
          {displayTextField(
            "Bank Fee Percent",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ bankFeePercent: e.target.value });
              }
            },
            formFieldData.bankFeePercent,
            false
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "Merchant Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({
                  merchantFee: e.target.value,
                });
              }
            },
            formFieldData.merchantFee,
            false
          )}
          {displayTextField(
            "Merchant Fee Percent",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ merchantFeePercent: e.target.value });
              }
            },
            formFieldData.merchantFeePercent,
            false
          )}
        </div>

        <div className="field-row-container">
          {displayTextField(
            "UBX Fee",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ ubxFee: e.target.value });
              }
            },
            formFieldData.ubxFee,
            false
          )}
          {displayTextField(
            "UBX Fee Percent",
            (e) => {
              if (validateNumberWithDecimal(e.target.value)) {
                updateFields({ ubxFeePercent: e.target.value });
              }
            },
            formFieldData.ubxFeePercent,
            false
          )}
        </div>
      </Grid>
    </Modal>
  );
};

export default PayoutFeeModal;
