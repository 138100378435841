import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch, message, Spin} from 'antd'
import enterpriseStyle from '../../kyb/forms/kybStyle'
import {history} from '../../../store/history'
import approvedCard from '../../../static/icons/approved.svg'
import ComplianceRequirementsModal from './compliance_requirements_modal'

const { TextArea } = Input;

const ApproveCard = props => {
    const kyb = enterpriseStyle()

    const [step, setStep] = React.useState("")
    const [dateSubmitted,setDateSubmitted] = React.useState("")
    const [dateApproved,setDateApproved] = React.useState("")
    const [dateModified,setDateModified] = React.useState("")
    const [submissionId,setSubmissionId] = React.useState("")
    const [remarks, setRemarks] = React.useState("")
    const [approvedBy, setApprovedBy] = React.useState("")
    const [showSpin, setShowSpin] = React.useState(false)
    const [showSaveRemarks, setShowSaveRemarks] = React.useState(false)
    const [currentRemarks, setCurrentRemarks] = React.useState("")
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false)
    const [confirmationType, setConfirmationType] = React.useState("")
    const [businessName, setBusinessName] = React.useState("")

    async function getRemarks(){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/kyb/${props.userId}/`,yourConfig)
            setShowSpin(true)
            setStep(response.data.step)
            setDateSubmitted(response.data.created_at)
            setDateModified(response.data.modified_at)
            setDateApproved(response.data.approval_date)
            setRemarks(response.data.remarks)
            setCurrentRemarks(response.data.remarks)
            setSubmissionId(response.data.id)
            setApprovedBy(response.data.approved_by)
            setBusinessName(response.data.business_name)
    }

    async function submitStatus(val){
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.put(
            process.env.REACT_APP_API_URL + `/api/admin/kyb/`,val,yourConfig)
        
            if(response.data.status == "failed"){
                message.error(response.data.message, 5)
            }

            else{
                message.success("Done")
                history.push('/submissions_kyb/')
            }
    }

    async function saveRemarks() {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        let response = await axios.patch(process.env.REACT_APP_API_URL + `/api/admin/kyb/remarks/`, 
            {
                submission_id: submissionId, 
                remarks: remarks
            }, 
            yourConfig
        )
        
        if(response.data.status == "failed"){
            message.error(response.data.message, 5)
        } else{
            message.success(response.data.message)
        }
    }

    function submitStep(val){
        let failed_steps =  props.failedSteps
        
        let params = {
            submission_id: submissionId,
            is_approved: val == 9 ? false : true,
            remarks,
            step_1_has_error: failed_steps[0] == false ? true : false,
            step_2_has_error: failed_steps[1] == false ? true : false,
            step_3_has_error: failed_steps[2] == false ? true : false,
            step_4_has_error: failed_steps[3] == false ? true : false,
            step_5_has_error: failed_steps[4] == false ? true : false,
            step_6_has_error: failed_steps[5] == false ? true : false
        }

        submitStatus(params)
    }

    function preApprovedDiv () {
        return(
            <div>
                 <div className={kyb.greatText}>
                    Approval
                </div>

                <div className={kyb.normalText} style={{marginTop: '20px'}}>
                    Date of Application: {dateSubmitted}
                </div>
                <div className={kyb.normalText}>
                    Last Update: {dateModified}
                </div>

            </div>
        )
    }

    function approvedDiv() {
        return(
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #D1D5DD'}}>
                    <div style={{marginTop: 16, paddingBottom: 16, }}>
                        <div className={kyb.greatText}>
                            Enterprise Application
                        </div>

                        <div className={kyb.normalText} style={{fontSize: 14, marginTop: 8}}>
                            Date of Application: <b>{dateSubmitted}</b>
                        </div>
                        <div className={kyb.normalText} style={{fontSize: 14}}>
                            Approved Date: <b>{dateApproved}</b>
                        </div>
                    </div>

                    <div>
                        <img src={approvedCard} />
                    </div>
                </div>

                <div className={kyb.normalText} style={{marginTop: 16}}>
                    Approved by: <b>{approvedBy}</b>
                </div>
            </div>
        )
    }

    React.useEffect(() => {
        getRemarks()
    }, [])


    return(
        <>
        <Card 
            className={kyb.formCardStyle}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            >      

                <div hidden={showSpin} align="center">
                    <Spin />
                </div>

                <div hidden={!showSpin}>
                    {
                        step >= 11 ?

                        approvedDiv()

                        :

                        preApprovedDiv()

                    }
                    <div style={{marginTop: 20}}>
                        <div className={kyb.miniText}>Remarks</div>
                            <TextArea 
                                maxLength={255} 
                                value={remarks} 
                                onChange={(e) => {
                                    setShowSaveRemarks(currentRemarks != e.target.value);
                                    setRemarks(e.target.value);
                                }}
                            />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                        <div>
                            { showSaveRemarks &&
                                <button 
                                    className={kyb.rejectBtn}
                                    onClick={() => {
                                        if (remarks == "") {
                                            message.error("Remarks is required")
                                            return;
                                        }
                                        saveRemarks();
                                    }}
                                >
                                    Save remarks
                                </button>
                            }
                        </div>
                        <div>
                            <button 
                                className={kyb.rejectBtn}
                                onClick={() => {
                                    if (remarks == "" || remarks == null) {
                                        message.error("Remarks is required")
                                        return;
                                    }
                                    setConfirmationType("reject");
                                    setOpenConfirmationModal(true);
                                }}
                            >
                                Reject
                            </button>
                            { step < 11 && 
                                <button className={kyb.approveBtn} 
                                    style={{opacity: props.moaFile == "" || props.ndaFile == "" ? 0.5 : 1, cursor: props.moaFile == "" || props.ndaFile == "" ? 'not-allowed' : 'pointer'}}
                                    disabled={props.moaFile == "" || props.ndaFile == ""}
                                    onClick={() => {
                                        if (remarks == "" || remarks == null) {
                                            message.error("Remarks is required")
                                            return;
                                        }
                                        setConfirmationType("approve");
                                        setOpenConfirmationModal(true);
                                    }}
                                >
                                    Approve
                                </button>
                            }
                        </div>
                    </div>

                    
                </div>
        </Card>

        <ComplianceRequirementsModal
            visible={openConfirmationModal}
            onCloseModal={() => { 
                setConfirmationType("")
                setOpenConfirmationModal(false) 
            }}
            onSubmit={() => { 
                switch (confirmationType) {
                    case "reject":
                        submitStep(9);
                        break;

                    case "approve":
                        submitStep(10);
                        break;
                
                    default:
                        break;
                }
                
                setOpenConfirmationModal(false)
            }}
            type={confirmationType}
            submissionId={submissionId}
            businessName={businessName}
            remarks={remarks}
            complianceProcess={"kyb"}
        />
        </>
        
    )
}

export default ApproveCard