import React, {useEffect} from 'react'
import axios from 'axios'
import {Card, Progress, Divider, Icon} from 'antd'
import enterpriseStyle from './kybStyle'
import Checked from '../../../static/icons/checked.svg'
import Unchecked from '../../../static/icons/unchecked.svg'
import Crossed from '../../../static/icons/crossed.svg'
import Pointy from '../../../static/icons/pointy.svg'
import { isMobile } from 'react-device-detect'
import { HelpOutlineOutlined } from '@material-ui/icons'


const ProgressCard = props => {
    const isSmallReso = window.screen.width * window.devicePixelRatio == 1331 && window.screen.height * window.devicePixelRatio == 669;
    const kyb = enterpriseStyle()
    const currentSteps = props.step
    const kybType = localStorage.getItem("KYBType")
    const kybStep = localStorage.getItem("KYBStep")
    const userRole = localStorage.getItem("userType")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const [showDialog, setShowDialog] = React.useState(false)
    const [index, setIndex] = React.useState()
    const reviewCardsDivRefs = props.reviewCardsDivRefs;
    const firstName = localStorage.getItem("firstName");
    const segments = localStorage.getItem("segments");
    let strokeColor = segments === 'me_' ? '#0DAED0' :
                        segments === 'gig' ? '#41BD5C' :
                        segments === 'sme' ? '#E3632A' :
                        segments === 'biz' ? '#0051B0' :
                        '#0A315E'

    function scroll_to_card_div(i) {
        if(i === 4){
            setShowDialog(true)
        }
        setIndex(i)
        // Pay attention to the order of listing
        const DIV_REFS_LISTING = [
            'business_info_div_ref',
            'contact_info_div_ref',
            'business_document_div_ref',
            'signatory_info_div_ref',
            'moa_nda_div_ref',
            'bank_account_div_ref',
            'aml_assessment_div_ref',
        ]

        let ref_in_focus = reviewCardsDivRefs[DIV_REFS_LISTING[i]];
        if (! ref_in_focus.current) {
            return;
        }

        ref_in_focus.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }


    const steps = [
        {
            step: 0,
            name: 'Business Information',
            disabled: currentSteps < 0 ? true : false
        },
        {
            step: 1,
            name: 'Contact Person Info',
            disabled: currentSteps < 1 ? true : false
        },
        {
            step: 2,
            name: 'Business Document',
            disabled: currentSteps < 2 ? true : false
        },
        {
            step: 3,
            name: 'Signatory Info',
            disabled: currentSteps < 3 ? true : false
        },
        {
            step: 4,
            name: 'MOA and NDA',
            disabled: currentSteps < 4 ? true : false
        },
        {
            step: 5,
            name: 'Bank Account',
            disabled: currentSteps < 5 ? true : false
        },
    ]

    {
        props.isAMLRequired &&
        steps.push(
            {
                step: 6,
                name: 'AML Assessment',
                disabled: currentSteps < 6 ? true : false
            }
        )
    }

    return(
        <div>
            <div 
                className={kyb.progressCardStyle}
                bodyStyle={{padding: '11px 0px 10px 0px'}}
                >
                <div style={{padding: '0px 16px 0px 16px'}}>
                    <div className={kyb.headerStyle} style={{fontSize: isMobile ? '16px' : isSmallReso ? '18px' : '24px', lineHeight: '32px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        Hello, {firstName}
                        {
                            adminRole.includes(userRole) && (
                                props.isAMLRequired ?
                                <span className={`${kyb.statusStyle} ${kyb.highRisk}`}>High-risk</span>
                                :
                                <span className={`${kyb.statusStyle} ${kyb.lowRisk}`}>Low-risk</span>
                            )
                        }
                    </div>
                    <div className="top-8" hidden={adminRole.includes(userRole) && kybType != 'creation'}>
                        <div className="normal-text-size liveColor">
                            Complete your profile
                        </div>
                        <Progress
                            percent={currentSteps >= 6 ? Math.min(100) : Math.min(currentSteps,6) * 15 } 
                            showInfo={false} 
                            strokeColor={{
                                '0%': strokeColor,
                                '100%': strokeColor
                            }}/>
                        <div align="right" className="normalTextStyle liveColor">
                            {currentSteps >= 6 ? Math.min(100) : Math.min(currentSteps,6) * 15 }%
                        </div>
                    </div>
                </div>
                {
                    !isMobile &&
                    <div className="top-20">
                        {
                            steps.map((item,i)=>{
                                return(
                                    <div key={i}
                                        style={{
                                            position: 'relative',
                                            height:'40px', 
                                            display: 'flex', 
                                            justifyContent:'space-between',
                                            borderRadius: 8,
                                            backgroundColor: (currentSteps == item.step) || (index == item.step) ? "#E2E8F0" : "",
                                            padding: isSmallReso ? '4px 18px 4px 18px' : '8px 18px 16px 18px',
                                            marginTop: isSmallReso ? '-2px' : ''
                                        }}
                                       >
                                        <div className={kyb.normalText} style={{fontSize: isSmallReso ? 12 : '14px', color: '#54575F'}}>
                                            <a href 
                                                onClick={() => scroll_to_card_div(i)} 
                                                style={(item.disabled ? enterpriseStyle.notAllowed : { color: '#2b2d32',
                                                    cursor: (userRole == 'ME' || userRole == 'CO' || userRole == 'SW' || item.disabled) && kybStep < 7 ? 'not-allowed' : 'pointer',})} 
                                                    disabled={(userRole == 'ME' || userRole == 'CO' || userRole == 'SW' || item.disabled) && kybStep < 7}
                                                    >
                                                {item.name}
                                            </a>
                                        </div>
                                        <div>
                                            {
                                                currentSteps <= item.step ? "" :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step1 == false && item.step == 0) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error1 && item.step == 0) ? <Icon src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step2 == false && item.step == 1) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error2 && item.step == 1) ? <img src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step3 == false && item.step == 2) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error3 && item.step == 2) ? <img src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step4 == false && item.step == 3) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error4 && item.step == 3) ? <img src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step5 == false && item.step == 4) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error5 && item.step == 4) ? <img src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step6 == false && item.step == 5) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error6 && item.step == 5) ? <img src={Crossed} alt="#"/> :
                                                (adminRole.includes(userRole) && kybType != "creation" && props.failedSteps.step7 == false && item.step == 6) || ((['CO','SW','AD'].includes(localStorage.getItem('userType')) && kybType == "creation" || adminRole.includes(userRole)) && props.error7 && item.step == 6) ? <img src={Crossed} alt="#"/> :
                                                currentSteps > item.step  ? <Icon type="check-circle" theme="filled" className={`text-${segments} icon-size`}/> : 

                                                ''
                                            }
                                        </div>

                                        <div hidden={i != 4 || showDialog || kybStep < 11 || adminRole.includes(userRole)} style={{position: 'absolute', top: '-8px', right: '-320px'}} 
                                            ><img src={Pointy} /></div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ProgressCard