import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch, message} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY, POSITION} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'

const ReviewBankAccount = props => {
    let datas = props.datas
    let segments = props.segments

    let kybLevel = localStorage.getItem("Level")
    let userRole = localStorage.getItem("userType")

    const kyb = enterpriseStyle()
    const [data, setData] = React.useState(datas ? datas.bank_account : [])
    const [toggleOn, SetToggleOn] = React.useState(true)
    let kybStep = localStorage.getItem("KYBStep")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'AP', 'EN', 'FI',]
    const editRole = ['AD','SD', 'BS']
    const hiddenSteps = [8, 9, 10, 11]

    let at = {
        'SA': 'Savings',
        'CU': 'Current',  
        'DI': 'Digital',
        'CH': 'Checking'    
        }
    
    let bank_account = [
        {   
            'account_name': data.account_name,
            'account_number': data.account_number,
            'account_type': at[data.account_type],
            'bank': data.bank,
            'bank_branch': data.branch
        }
    ]

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 6)
        }
    }

    React.useEffect(() => {
        submitFailed(datas ? !datas.step_6_has_error : false)
    },[])

    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                border: toggleOn && 
                        adminRole.includes(userRole)
                        ? '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                ''
            }
    
 
            <div style={{margin: 'px 0px 32px 0px', display: 'flex', justifyContent: 'space-between'}} ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>Bank Account</div>

                <div hidden={(['CO','SW'].includes(localStorage.getItem('userType')) &&  hiddenSteps.includes(parseInt(kybStep))) ||
                            editRole.includes(userRole) && localStorage.getItem('KYBType') !== 'creation'}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(6)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            {
                bank_account.map((item, key) => {
                    return(
                        <div key={key} className="bottom-32" hidden={item.first_name == ""}>       
                                <div className={`${kyb.tableStyle} liveColor`}>
                                    <div className="twoCol top-20">
                                        <div>
                                            Account Name: 
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.account_name}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Account Number: 
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.account_number}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Account Type: 
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.account_type}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Bank: 
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.bank}
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                            <div>
                                            Bank Branch: 
                                        </div>
                                        <div className={kyb.valueText}>
                                            {item.bank_branch}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )
                })
            }
            
        </Card>
    )
}

export default ReviewBankAccount