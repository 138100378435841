import React from 'react'
import axios from 'axios'
import {Card, Select, Input, Icon, Switch, message} from 'antd'
import enterpriseStyle from '../kybStyle'
import { isMobile } from 'react-device-detect'
import {NATURE_BUSINESS, NATIONALITY1, POSITION, segments} from '../../../constants/constants'
import Passed from '../../../../static/img/passed.svg'
import Failed from '../../../../static/img/failed.svg'

const ReviewBusinessInfo = props => {
    let datas = props.datas
    let segments = props.segments

    const kyb = enterpriseStyle()
    const userRole = localStorage.getItem("userType")
    let kybStep = localStorage.getItem("KYBStep")
    let kybLevel = localStorage.getItem("Level")
    const [directors, setDirectors] = React.useState(datas ? datas.directors : [])
    const [stockholders, setStockholders] = React.useState(datas ? datas.stockholders : [])
    const [officers, setOfficers] = React.useState(datas ? datas.officers : [])
    const [data, setData] = React.useState(datas ? datas : [])
    const [toggleOn, SetToggleOn] = React.useState(datas ? !datas.step_1_has_error : false)
    let businessType = data.business_type === 0 ? 'Sole Proprietor' : data.business_type === 1 ? 'Corporation' : 'Partnership'
    localStorage.setItem("lastBusinessType", data.business_type)
    let businessNature = NATURE_BUSINESS[data.nature_of_business]
    let fullAddress = (data.address_unit+" "+data.address_street+" "+data.address_subdivision+" "+data.address_barangay
                        +" "+data.address_city+" "+data.address_province+" "+data.address_zip_code)

    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SW', 'SD', 'BS']
    const createRole = ['AD', 'SD']
    const hiddenSteps = [8, 9, 10, 11]

    let businessDeclaration = [
        {
            'name': 'Office Phone No.',
            'value': data.phone
        },
        {
            'name': 'Email Address',
            'value': data.email
        },
        {
            'name': 'Years in Business',
            'value': data.years_in_business
        },
    ]

    let website_sma = [
        {
            'name': 'Website',
            'value': data.website
        },
        {
            'name': 'Facebook',
            'value': !data.facebook || data.facebook == null ? 'Add Facebook' : <a href={data.facebook} target="_blank">{data.facebook}</a>
        },
    ]

    // React.useEffect(() => {
    //     if(localStorage.getItem('userType') != 'CO') {
    //         fetchData()
    //     }
    // },[])

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    //Call Back from mainReiew_admin.js
    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 1)
        }
    }

    return(
        <Card 
            className={`${kyb.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                
                border: toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole) ? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} /> 
                     </div>
                     <div align='right'>
                        <span className={kyb.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                ''
            }
            
            <div className="bottom-32 twoCol" ref={props.cardDivRef}>
                <div className={`${kyb.greatText} liveColor`}>Business Information</div>

                <div hidden={(['CO','SW'].includes(localStorage.getItem('userType')) && hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(1)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            <div className={`${kyb.tableStyle} liveColor`}>
                <div className="twoCol">
                    <div>
                        Business Type:
                    </div>
                    <div className={kyb.valueText}>
                        {businessType}
                    </div>
                </div>
                <div className="twoCol top-20">
                    <div>
                        Business Name:
                    </div>
                    <div className={kyb.valueText}>
                        {data.business_name}
                        
                    </div>
                </div>
                <div className="twoCol top-20">
                    <div>
                        Nature of Business:
                    </div>
                    <div className={kyb.valueText}>
                        {businessNature}
                    </div>
                </div>
            </div>

            <div className="kyb-margin-style">
                <div className={kyb.headerStrong}>
                    Address
                </div>

                <div className={kyb.tableStyle}>
                    <div className={kyb.valueText}>
                        {fullAddress}
                    </div>
                </div>
            </div>

            <div className="kyb-margin-style">
                <div className={kyb.headerStrong}>
                    Business Declaration
                </div>

                <div className={`${kyb.tableStyle} liveColor`}>
                    {
                        businessDeclaration.map((item, key) => {
                            return(
                                <div key={key} className={'twoCol top-20'} 
                                hidden={data.years_in_business < 2 && (key === 7)}>
                                    <div>
                                        {item.name}:
                                    </div>
                                    <div className={kyb.valueText}>
                                        {item.value}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            <div className="kyb-margin-style" hidden={data.business_type == 0 || true}>
                <div className={kyb.headerStrong}>
                    {data.business_type == 2 ? 'List of Partners' : 'List of Directors'}
                </div>

                {
                    directors.map((item, i) => {
                        return(
                            <div key={i}>
                                <div className={`${kyb.tableStyle} padding-bot-20`} style={{borderBottom: '1px solid #E6EAF0'}}>
                                    <div className="twoCol top-20">
                                        <div>
                                            Name:
                                        </div>
                                        <div className={kyb.valueText}>
                                        {
                                            item.middle_name ? 

                                            item.first_name+" "+item.middle_name+" "+item.last_name

                                            :

                                            item.first_name+" "+item.last_name
                                        }
                                        </div>
                                    </div>
                                    <div className="twoCol top-20">
                                        <div>
                                            Nationality:
                                        </div>
                                        <div className={kyb.valueText}>
                                        {NATIONALITY1[item.nationality]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        )
                    })
                }
            </div>

            <div className="kyb-margin-style" hidden={data.business_type == 0 || data.business_type == 2 || true}>
                <div className={kyb.headerStrong}>
                    List of Stockholders
                </div>


                {
                    stockholders.map((item, i) => {
                        return(
                            <div key={i}>
                            <div className={`${kyb.tableStyle} padding-bot-20`} style={{borderBottom: '1px solid #E6EAF0'}}>
                                <div className="twoCol top-20">
                                    <div>
                                        Name:
                                    </div>
                                    <div className={kyb.valueText}>
                                        {
                                            item.middle_name ? 

                                            item.first_name+" "+item.middle_name+" "+item.last_name

                                            :

                                            item.first_name+" "+item.last_name
                                        }
                                    </div>
                                </div>
                                <div className="twoCol top-20">
                                    <div>
                                    % of Shares:
                                    </div>
                                    <div className={kyb.valueText}>
                                    {item.percent_shares}%

                                    </div>
                                </div>
                                <div className="twoCol top-20">
                                    <div>
                                        Nationality:
                                    </div>
                                    <div className={kyb.valueText}>
                                    {NATIONALITY1[item.nationality]}
                                    </div>
                                </div>
                                </div>
                            </div>
                            
                        )
                    })
                }
            </div>

            <div className="kyb-margin-style" hidden={data.business_type == 0 || data.business_type == 2}>
                <div className={kyb.headerStrong}>
                    List of Officers
                </div>


                {
                    officers.map((item, i) => {
                        return(
                            <div key={i}>
                            <div className={`${kyb.tableStyle} padding-bot-20`} style={{borderBottom: '1px solid #E6EAF0'}}>
                                <div className="twoCol top-20">
                                    <div>
                                        Name:
                                    </div>
                                    <div className={kyb.valueText}>
                                    {
                                        item.middle_name ? 

                                        item.first_name+" "+item.middle_name+" "+item.last_name

                                        :

                                        item.first_name+" "+item.last_name
                                    }
                                    </div>
                                </div>
                                <div className="twoCol top-20">
                                    <div>
                                        Position:
                                    </div>
                                    <div className={kyb.valueText}>
                                    {POSITION[item.position]}
                                    </div>
                                </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="kyb-margin-style">
                <div className={kyb.headerStrong}>
                    Website and Social Media Account
                </div>
                <div className={`${kyb.tableStyle} liveColor`}>
                    {
                        website_sma.map((item, key) => {
                            return(
                                <div key={key} className={'twoCol top-20'} 
                                hidden={data.years_in_business < 2 && (key === 7)}>
                                    <div>
                                        {item.name}:
                                    </div>
                                    <div className={kyb.valueText}>
                                        {item.value}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Card>
    )
}

export default ReviewBusinessInfo