import React from 'react'
import {Card, Icon, Switch} from 'antd'
import amlAssessmentStyle from './amlAssessmentStyle'
import Passed from '../../static/img/passed.svg'
import Failed from '../../static/img/failed.svg'

const ReviewAMLAssessment = props => {
    let data = props.datas;
    let segments = props.segments
    const userRole = localStorage.getItem("userType")
    const amlStyle = amlAssessmentStyle()
    const [toggleOn, SetToggleOn] = React.useState(true)
    
    let kybStep = localStorage.getItem("KYBStep")
    const adminRole = ['AD', 'KB', 'SD', 'OP', 'BS', 'AP', 'EN', 'FI',]
    const editRole = ['AD', 'CO', 'SW', 'SD', 'BS']
    const hiddenSteps = [8, 9, 10, 11]

    React.useEffect(() => {

    },[])
    
    const legal_docu = {
        'name': 'AML Questionnaire',
        'file_name': data.aml_questionnaire_attachment == null || data.aml_questionnaire_attachment == "" ? null : data.aml_questionnaire_attachment
    }

    function editBusinessInfo(val){
        props.openEdit(val)
    }

    function submitFailed(val){
        SetToggleOn(val)
        if(adminRole.includes(userRole) && localStorage.getItem("KYBType") != "creation"){
            props.setFailedStep(val, 5)
        }
    }

    return(
        <Card 
            className={`${amlStyle.formCardStyle} padding-16`}
            bodyStyle={{padding: '20px 16px 20px 16px'}}
            style={{
                border: toggleOn && 
                        adminRole.includes(userRole)
                        ? '2px solid #1DD28B' : 
                        !toggleOn && 
                        adminRole.includes(userRole)? 
                        '2px solid #E24C4C' : ''
            }}>

            {
                adminRole.includes(userRole) ?
                <div style={{position: 'relative', paddingBottom: 16, 
                            borderBottom: '1px solid #D1D5DD', marginBottom: 16}}>    
                     <div style={{position: 'absolute', top: '-20px', left: '-17px'}}>
                        <img src={toggleOn ? Passed : Failed} />    
                     </div>
                     <div align='right'>
                        <span className={amlStyle.miniText} style={{opacity: props.curStep >= 11 ? 0.5 : ''}}>
                            Mark as Passed: <Switch size="small" disabled={props.curStep >= 11} checked={toggleOn} onChange={() => submitFailed(!toggleOn)}/>
                        </span>
                     </div>
                </div>

                :
                
                ''
            }
            
            <div style={{margin: '0px 0px 32px 0px', display: 'flex', justifyContent: 'space-between'}} ref={props.cardDivRef}>
                <div className={`${amlStyle.greatText} liveColor`}>AML Assessment</div>

                <div hidden={(['CO','SW'].includes(localStorage.getItem('userType')) &&  hiddenSteps.includes(parseInt(kybStep)))}>
                    <button className={`outline-btn--${segments}`} onClick={() => editBusinessInfo(7)}>
                        <Icon type="edit" className={`text-${segments} right-4`}/>Edit
                    </button>
                </div>
            </div>

            <div className={`${amlStyle.tableStyle} liveColor`}>
                <div className="twoCol top-20">
                    <div>
                        {legal_docu.name}
                    </div>
                    <div className={amlStyle.valueText} style={{fontWeight: 200}}>
                        <div>
                            <a href={legal_docu.file_name} target="blank" className={`text-${segments} medium-font-weight`}>View</a>{adminRole.includes(userRole) || ['CO','SW'].includes(localStorage.getItem('userType')) && kybStep == 11 ?<span> | <a href={legal_docu.file_name} target="_blank" download className={`text-${segments} medium-font-weight`}>Download</a></span>  : ''}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ReviewAMLAssessment;