import React, { useState, useEffect } from "react";
import { Pagination, Table, Card, Switch, message } from "antd";
import {
  SearchInput,
  FilterBar,
} from "../../../../screens/admin/cashbux/components";
import "../css/payout_fees.css";

const PayoutFeesTable = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const {
    fetchPayoutFeeList,
    togglePayoutFee,
    payoutFeesData,
    isLoading,
    success,
    messageDisplay,
    openEditModal,
    isModalOpen,
    id,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      if (messageDisplay != "" && success == true) {
        message.destroy();
        message.success(messageDisplay);
      } else if (messageDisplay != "" && success == false) {
        message.destroy();
        message.error(messageDisplay);
      }
    }, 1000);
  }, [messageDisplay, success]);

  useEffect(() => {
    fetchPayoutFeeList(searchQuery, currentPage, id);
  }, [currentPage]);

  useEffect(() => {
    if (!isModalOpen) {
      fetchPayoutFeeList(tempSearchQuery, currentPage, id);
    }
  }, [isModalOpen]);

  const columns = [
    {
      title: "Provider",
      dataIndex: "provider",
      fixed: "left",
    },
    {
      title: "Channel",
      dataIndex: "channel",
    },
    {
      title: "Channel Code",
      dataIndex: "channel_code",
    },
    {
      title: "Bank Fee",
      dataIndex: "bank_fee",
    },
    {
      title: "Bank Fee %",
      dataIndex: "bank_fee_percent",
    },
    {
      title: "Merchant Fee",
      dataIndex: "merchant_fee",
    },
    {
      title: "Merchant Fee %",
      dataIndex: "merchant_fee_percent",
    },
    {
      title: "UBX Fee",
      dataIndex: "ubx_fee",
    },
    {
      title: "UBX Fee %",
      dataIndex: "ubx_fee_percent",
    },
    {
      title: "Date Updated",
      dataIndex: "modified_at",
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
    },
    {
      title: "Enable",
      dataIndex: "archived",
      render: (text, record) => (
        <Switch
          checked={!text}
          className="payout-table-switch"
          onChange={async () => {
            await togglePayoutFee(record.id);
            fetchPayoutFeeList(tempSearchQuery, currentPage, id);
          }}
        />
      ),
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <a onClick={() => openEditModal(record)} className="edit-payout-fee">
            Edit
          </a>
        );
      },
    },
  ];

  return (
    <Card className="payout-fee-card">
      <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
        <FilterBar
          initialValue={""}
          selectedOption={""}
          searchQuery={searchQuery}
          clearFilters={() => {
            setCurrentPage(1);
            setSearchQuery("");
            setTempSearchQuery("");
            fetchPayoutFeeList("", 1, id);
          }}
        />
        <SearchInput
          tempSearchQuery={tempSearchQuery}
          setTempSearchQuery={setTempSearchQuery}
          handleApplySearch={() => {
            setCurrentPage(1);
            setSearchQuery(tempSearchQuery);
            fetchPayoutFeeList(tempSearchQuery, 1, id);
          }}
          placeholder="Search by Channel Name or Channel Code"
        />
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          dataSource={payoutFeesData.data}
          columns={columns}
          pagination={false}
          loading={isLoading}
          scroll={{ x: true }}
        />

        <Pagination
          size="small"
          total={(payoutFeesData && payoutFeesData.total) || 0}
          defaultPageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={setCurrentPage}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          style={{ paddingTop: "12px", textAlign: "right" }}
        />
      </div>
    </Card>
  );
};

export default PayoutFeesTable;
