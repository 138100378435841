import {isMobile} from 'react-device-detect'
import { makeStyles } from '@material-ui/core';
let kybStep = localStorage.getItem("KYBStep")
const isHighReso = window.screen.width * window.devicePixelRatio >= 1440 && window.screen.height * window.devicePixelRatio >= 900;
const isFHD = window.screen.width * window.devicePixelRatio >= 1920 && window.screen.height * window.devicePixelRatio >= 1080;
const isSmallReso = window.screen.width * window.devicePixelRatio == 1331 && window.screen.height * window.devicePixelRatio == 669;

const enterpriseStyle = makeStyles(theme => ({

    progressCardStyle: {
        width: isMobile ? '100%' : isHighReso ? 280 : isSmallReso ? 240 : '258px',
        // backgroundColor: '#fff',
    },

    displayResponsive: {
        display: isMobile ? 'block' : 'flex',
        marginTop: '20px'
    },

    formCardStyle: {
        width: isMobile ? '100%' : isHighReso ? '584px' : isSmallReso ? '550px' : '634px',
        backgroundColor: '#fff',
        border: '1px solid #D1D5DD',
        borderRadius: 10,
        marginBottom: isMobile && kybStep < 7 ? 80 : isMobile && kybStep > 7 ? 20 : 20,
       
    },

    noteCard: {
        padding: '12px 16px 16px 16px',
        background: '#fff1cb',
        marginTop: isHighReso ? '-16px' : 16,
        width: '100%'
    },

    inputReadOnly: {
        color: '#000000',
        opacity:'0.6',
        background: '#E6EAF0',
        border: '1px solid #E6EAF0',
        height: '32px',
        padding: '8px',
        width: '100%',
        borderRadius: '4px'
    },

    inputStyle: {
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        border: '1px solid #D1D5DD',
        boxSizing: 'border-box',
        paddingLeft: '8px'

    },

    submitBtn: {
        marginTop: 32,
        width: '98%',
        height: '40px',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #F5922F',
        background: '#F5922F',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: 0.8
        },
        '&:active': {
            background: '#DA7F24',
            opacity: 1
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        }
    },

    uploadingBtn: {
        marginTop: 32,
        width: '100%',
        height: '40px',
        color: '#000000',
        fontWeight: '500',
        fontSize: 14,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #f5f5f4',
        background: '#f5f5f4',
        cursor: 'pointer',
        outline: 0,
    },

    removeBtn: {
        width: '30%',
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '0px solid #F5922F',
        background: '#fff',
        cursor: 'pointer',
        outline: 0,
        [theme.breakpoints.down("sm")]: {
 

        }
    },

    editBtn: {
        width: '80px',
        height: '40px',
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #F5922F',
        background: '#fff',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            background: '#f5922f',
            color: '#fff'
        },
        '&:active': {
            background: '#DA7F24',

        }
    },


    addBtn: {
        marginTop: 16,
        marginRight: '2%',
        width: isSmallReso ? '40%' : isHighReso ? '40%' : '30%',
        height: '40px',
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #F5922F',
        background: '#fff',
        cursor: 'pointer',
        outline: 0,
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        '&:hover': {
            background: '#f5922f',
            color: '#fff'
        },
        '&:active': {
            background: '#DA7F24',

        }
    },

    downloadBtn: {
        width: '100%',
        padding: '8px',
        color: '#F5922F',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '24px',
        borderRadius: '10px',
        border: '1px solid #F5922F',
        background: '#fff',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            background: '#f5922f',
            color: '#fff'
        },
        '&:active': {
            background: '#DA7F24',

        }
    },

    linkStyle:{ 
        color: '#F5922F',
        fontWeight: '600'
    },

    uploadBtnStyle: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1D82B8',
        border: '1px solid #1D82B8',
        width: '100%',
        height: '48px',
    },

    fileName: {
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        color: '#54575F',
        width: '65%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: '30px'
    },

    uploadDiv:{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #E6EAF0',
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: 16
    },

    uploadFile: {
        color: '#f5922f',
        boxSizing: 'border-box',
        borderRadius: '10px',
        border: '1px solid #f5922f',
        fontWeight: 'bold',
        width: '102px',
        height: '40px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        outline: 0,
        cursor: 'pointer',
    },

    allGoodBtn: {
        color: '#fff',
        background: '#F5922F',
        borderRadius: '10px',
        border: '1px solid #f5922f',
        fontWeight: 600,
        width: '165px',
        height: '40px',
        cursor: 'pointer',
        outline: 0,
        '&:hover': {
            opacity: 0.8
        },
        '&:active': {
            background: '#DA7F24',
            opacity: 1

        }
    },

    rejectBtn: {
        color: '#0A315E',
        background: '#fff',
        border: '1px solid #0A315E',
        borderRadius: '4px',
        fontWeight: '600',
        width: '120px',
        height: '40px',
        outline: 0,
        cursor: 'pointer',
        // marginRight: 11,
    },

    approveBtn: {
        marginLeft: 16,
        color: '#fff',
        background: '#0A315E',
        border: '1px solid #0A315E',
        borderRadius: '4px',
        fontWeight: '600',
        width: '120px',
        height: '40px',
        outline: 0,
        cursor: 'pointer'
    },

    tableStyle: {
        // border: '1px solid #E6EAF0',
        // boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '4px',
        marginTop: 8,
    },
    
    innerTableStyle: {
        padding: '16px 20px',
        borderBottom: '1px solid #e6eaf0',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: '',
            justifyContent: '',
        }
    },

    singleValue: {
        padding: '16px 20px',
        fontWeight: 'bold',
    },

    stickyBtn: {
        marginLeft: '-16px',
        padding: '8px 16px',
        boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
        position: "fixed",
        zIndex: "100",
        bottom: "0",
        width: '100%',
        background: '#fff'
    },

    stickyDiv: {
        marginLeft: '-50vw',
        padding: '24px 16px 16px 32px',
        boxShadow: '0px -10px 10px rgba(0, 0, 0, 0.04)',
        position: "fixed",
        // zIndex: "100",
        bottom: "0",
        width: '100%',
        background: '#1D82B8'
    },

    modalBodyTips: {
        marginTop: '50px'
    },

    pending: {
        border: '1px solid #fca039',
        borderLeft: '4px solid #fca039',
        background: '#fef3df',
        padding: 4,
        width: 80,
        fontSize: '10',
        borderRadius: '4px'
    },

    //fonts 
    tipsStyle: {
        marginTop: 8,
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2B2D33'
    },

    textStyle3: {
        padding: '3px 0px 3px 0px',
        fontSize: '12px',
        lineHeight: '24px',
        color: '#2b2d33',
        opacity: '0.8',
    },

    headerStyle: {
        fontSize: isMobile || isHighReso ? '16px' : '24px',
        fontWeight: 'bold',
        color: '#2b2d32', 

        [theme.breakpoints.down("sm")]: {
            fontSize: 16
        }
    },

    headerReview: {
        fontSize: isMobile ? '16px' : '24px',
        fontWeight: 'bold',
        color: '#2b2d32'
    },

    miniText: {
        fontSize: '12px',
        lineHeight: '12px',
        // color: '#212B36', 
        marginBottom: '5px'
    },

    normalText: {
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
    },

    headerStrong: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '24px',
        color: '#2b2d32',

        [theme.breakpoints.down("sm")]: {
            fontSize: 14
        }
    },

    smallNormalText: {
        fontSize: 14,
        lineHeight: '20px',
        marginTop: 8,
    },
    
    uploadText: {
        color: '#909196',
        fontSize: 16,
        lineHeight: '24px',
    },

    greatText: {
        fontSize: 18,
        lineHeight: '20px',
        fontWeight: 'bold',
    },

    reviewText: {
        fontSize: isMobile ? 12 : 14,
        lineHeight: '24px',
        marginTop: 8,
    },

    valueText: {
        width: isMobile ? '150px' : '50%',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        fontWeight: '500',
    },

    hoverState: {
        '&:hover': {
            background: '#E6EAF0',
        }
    },

    uploadedCard: {
        background: '#d1ffed',
        borderRadius: '10px',
        border: '1px solid #d1ffed',
        width: '100px',
        height: '37px',
        color: '#1DD28B',
        fontWeight: 'bold',
        outline: 0,
        marginRight: 4,
        
    },
    notAllowed:{
        cursor: 'not-allowed !important',
        color: '#808080',
    },
    mobileTipsBtnStyle: {
        width: '100%',
        height: '100%',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '12ox',
        lineHeight: '16px',
        color: '#2B2D33',
        height: '32px',
        borderRadius: '33px',
        border: '1px solid #e6eaf0',
        alignItems: 'center'
    },
    textStyle: {
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px',
        color: '#000000'
    },
    presentStyle: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#54575F',
        wordWrap: 'break-word'
    },
    uploadPhoto: {
        height: '28px',
        border: '1px solid #2b2d32',
        color: '#2b2d32',
        fontWeight: 'normal',
        margin: '18px 0px 10px 0px'
    },
    backToDashboard: {
        fontSize: '16px',
        fontWeight: '700',
        margin: '0px',
    },
    submitBtnStyle: {
        width: isMobile ? '49%' : '173px',
        height: isHighReso? '42px' : '48px',
        color: '#fff',
    },
    boldText: {
        fontStyle: 'normal',
        fontSize: isMobile || isHighReso ? '14px' : '16px',
        fontWeight: 'bold',
        lineHeight: isMobile ? '18px' : '24px',
    },
    cameraCardWeb: {
        boxShadow: '0px -7px 10px rgba(0, 0, 0, 0.04)',
        position: "fixed",
        zIndex: "200",
        top: "5%",
        left: "10%",
        width: '80%',
        height: '90%',
        background: '#2b2d33'
    },
    closeCamera: {
        background: '#fff',
        border: '1px solid #fff',
        color: '#2b2d33',
        fontWeight: '600',
        height: '50px',
        width: '50px',
    },
    captureBtn: {
        height: '60px',
        width: '60px',
        zIndex: '999',
        borderRadius: '50%',
        border: '5px solid #f5f5f4',
        background: '#1d82b3',
        color: '#fff'
    },
    selfieUploadDiv: {
        width: '100%',
        height: '82px',
        border: '1px solid #E6EAF0',
        borderRadius: '4px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px',
    },
    selfieFileName: {
        fontSize: '16px', 
        color: '#2b2d33',
        margin: '15px 0px 0px 10px',
        width: '350px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap', 
    },
    statusStyle: {
        borderRadius: '4px',
        padding: '4px 8px',
        fontSize: '12px',
        fontWeight: '400',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
    },
    highRisk: {
        backgroundColor: '#F6E9E7',
        border: '1px solid #E77352',
    },
    lowRisk: {
        backgroundColor: '#E5F2FD',
        border: '1px solid #5DA2F0',
    }
    
    
}))

export default enterpriseStyle;