import React, { useEffect } from 'react';
import dashboardStyles from '../../../static/css/Dashboard';
import { history } from '../../../store/history';
import AllBankLedger from './allbank_ledger';

const CashBuxBankLedger = props => {

  const db = dashboardStyles();

  useEffect(() => {
    if (localStorage.getItem("jwtToken") == "" || localStorage.getItem("jwtToken") == null) {
      history.push('/login')
    } else {
      if (localStorage.getItem("userType") == "ME" || localStorage.getItem("userType") == "CO") {
        history.push('/dashboard')
      } else if (localStorage.getItem("userType") == "PS") {
        history.push('/orders')
      } else if (localStorage.getItem("userType") == "PF") {
        history.push('/recon/7_eleven')
      } else if (localStorage.getItem("userType") == "MA") {
        history.push('/overview')
      }
    }
  }, []);

  return(
    <div className={db.main}>
      <div className={db.dashboardStyle}>
          Summary
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          width: '100%',
        }}
      >
        <AllBankLedger 
          accountType="disbursements"
        />
      </div>
    </div>
    )
}

export default CashBuxBankLedger